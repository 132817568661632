import { Flex, Icon, Img, Radio, RadioGroup, Text, Tooltip } from "@chakra-ui/react";
import { CardReceimentData, CardTaxData } from "@/models";
import { RadioInput } from "../RadioInput";
import { formatCurrency, getApplicationName } from "@/utils";
import { InstallmentArray } from "../useAdvanceReceipt";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { usePreviewContext } from "@/contexts/PreviewContext";

interface RadioGroupBodyProps {
  array: InstallmentArray;
  setCreditCardReceivement: (data: CardReceimentData) => void;
  creditCardReceivement: "RECURRENT" | "EARLY";
  setCreditCardTax: (data: CardTaxData) => void;
  creditCardTax: "EARLY_BUYER" | "EARLY_SELLER";
  anchoringState?: boolean;
  setAnchoringState?: (param: boolean) => void;
  creditCardType?: string;
  isPunctualCharge?: boolean;
  selectedInstallments: string;
}

export function RadioGroupBody({
  setCreditCardReceivement,
  creditCardReceivement,
  setCreditCardTax,
  creditCardTax,
  array,
  isPunctualCharge,
  selectedInstallments
}: RadioGroupBodyProps) {
  const isLocalhost = window?.location?.hostname.includes("localhost")
  const applicationName = getApplicationName({ type: isLocalhost ? "localhost" : "application" });
  const { previewData, setPreviewData } = usePreviewContext();
  const [hasToogle, setHasToogle] = useState<boolean>(false);
  const taxV2 = localStorage.getItem("isActiveForTax")
  const hasView = localStorage?.getItem("barte-features-list-user")?.includes("view_new_preview_payment_link");
  useEffect(() => {
    if (hasView) {
      setHasToogle(hasView)
    }

  }, [hasView])

  useEffect(() => {
    if (taxV2 === "true") {
      setCreditCardTax("EARLY_SELLER")
    }

    setCreditCardTax("EARLY_SELLER")
  }, [taxV2])

  useEffect(() => {
    if (Number(selectedInstallments) > 12 && applicationName === "stanleyspay") {
      setCreditCardTax("EARLY_BUYER")
    }
  }, [selectedInstallments])


  useEffect(() => {
    setPreviewData({ ...previewData, creditCardTax: creditCardTax || "EARLY_BUYER" })

  }, [creditCardTax])


  useEffect(() => {
    setPreviewData({ ...previewData, arrayInstallments: array, creditCardTax: creditCardTax })
  }, [array])



  return (
    <>
      <RadioGroup
        onChange={setCreditCardReceivement}
        value={creditCardReceivement}
      >
        <Flex flexDir="column" >
          <Flex flexDir="column">
            <Flex align="center" gap=".3rem">
              <Text lineHeight="1.5rem" fontSize="12px">
                Recebimento
              </Text>
              <Tooltip
                placement="top"
                hasArrow
                label="
                Você pode optar por receber parcelado recorrente
                conforme configurações de pagamento acima ou
                receber antecipado mesmo que seu cliente pague
                parcelado."
              >
                <span>
                  <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                </span>
              </Tooltip>
            </Flex>
            <Flex flexDir="row" justify="space-between">
              <RadioInput active={creditCardReceivement === "EARLY"}>
                <Radio value="EARLY" borderColor="newGray.100" />
                <Text fontSize="14px">Antecipado</Text>
              </RadioInput>

              <RadioInput active={creditCardReceivement === "RECURRENT"}>
                <Radio value="RECURRENT" borderColor="newGray.100" />
                <Text fontSize="14px">Recorrente</Text>
              </RadioInput>
            </Flex>
            {creditCardReceivement === "EARLY" && (
              <>
                <Text fontSize="12px" color="newGray.400">
                  O recebimento antecipado acontece apenas para o pagamento em
                </Text>
                <Text fontSize="12px" color="newGray.400" fontWeight="600">
                  cartão de crédito.
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </RadioGroup>
      {(creditCardReceivement === "EARLY" && taxV2 === "false") && (
        <>
          <RadioGroup onChange={setCreditCardTax} value={creditCardTax}>
            <Flex flexDir="column">
              <Flex align="center" gap=".3rem">
                <Text lineHeight="1.5rem" fontSize="12px">
                  Taxa de Antecipação das Parcelas
                </Text>
                <Tooltip
                  placement="top"
                  hasArrow
                  label="
                  Você pode optar por repassar as taxas de adiantamento
                  ao seu cliente na forma de juros de parcelameno ou pagar
                  você, mantendo o pagamento com valor fixo independente
                  de parcelas."
                >
                  <span>
                    <Icon w=".8rem" color="gray.400" as={AiOutlineInfoCircle} />
                  </span>
                </Tooltip>
              </Flex>

              <Flex flexDir="row" justify="space-between">
                <RadioInput active={creditCardTax === "EARLY_BUYER"}>
                  <Radio value="EARLY_BUYER" borderColor="newGray.100" />
                  <Text fontSize="14px">Cliente</Text>
                </RadioInput>
                <RadioInput active={creditCardTax === "EARLY_SELLER"}>
                  <Radio value="EARLY_SELLER" borderColor="newGray.100" />
                  <Text fontSize="14px">Você</Text>
                </RadioInput>

                {/* {(Number(selectedInstallments) <= 12 && import.meta.env.VITE_APPLICATION_NAME !== "barte") && (
                  <RadioInput active={creditCardTax === "EARLY_SELLER"}>
                    <Radio value="EARLY_SELLER" borderColor="newGray.100" />
                    <Text fontSize="14px">Você</Text>
                  </RadioInput>
                )} */}
              </Flex>
              <Text lineHeight="1.5rem" fontSize="12px" color="newGray.400">
                {creditCardTax === "EARLY_BUYER" ?
                  "Seu cliente assume as taxas e as taxas de processamento continuam sendo pagas por você" :
                  "Você assume as taxas"
                }
              </Text>
            </Flex>
          </RadioGroup>
          {/* {array && (
            <Flex
              flexDir="column"
              border="1px solid"
              borderColor="newGray.100"
              borderRadius="4px"
              padding=".8rem"
              gap="35px"
            >

              <Text
                fontSize=".9rem"
                color="black"
                fontWeight="400"
              >
                Valor base apresentado para o cliente: <b>
                  {formatCurrency({ value: array?.installmentsToBuyer?.at(0)?.totalValue })}
                </b>
                {creditCardTax === "EARLY_SELLER" && hasToogle === true && (
                  <Text
                    fontSize=".9rem"
                    color="newGray.400"
                    fontWeight="400"
                  >
                    Parcelamento máximo selecionado: <b>
                      {array?.installmentsToSeller.length}x
                    </b>
                  </Text>)
                }
              </Text>


              <Flex
                flexDir="column"
                gap=".5rem"
              >
                <Flex
                  w="100%"
                  justifyContent="space-between"

                >
                  <Text
                    w="120%"
                  >
                    Cliente paga
                  </Text>
                  <Text
                    w="80%"

                  >
                    Você recebe
                  </Text>
                </Flex>
                {creditCardTax === "EARLY_BUYER" && (
                  <Flex flexDir="column"
                    gap=".5rem">

                    {array?.installmentsToBuyer?.map((item, index) => (
                      <Flex
                        justifyContent="space-between"
                      >
                        <Flex gap=".5rem"
                          w="130%"

                        >
                          <Flex flexDirection="column">
                            <Flex
                              gap="10px"
                            >
                              <Text
                                fontSize=".9rem"
                                color="newGray.400"
                                fontWeight="400"
                                aria-label={`Amount paind in ${index + 1} times`}
                              >
                                {item?.installments}x
                              </Text>
                              <Text
                                fontSize=".9rem"
                                color="black"
                                fontWeight="400"
                                aria-label={`Amount paind in ${index + 1} times`}
                              >
                                {formatCurrency({ value: item?.installmentAmount })}
                              </Text>
                            </Flex>

                            <Text
                              fontSize=".9rem"
                              color="newGray.400"
                              fontWeight="400"
                              aria-label={`Amount paind in ${index + 1} times`}
                            >
                              ({formatCurrency({ value: item?.totalAmount })})

                            </Text>

                          </Flex>
                          {item?.discount > 0 && item?.discount !== null && (
                            <Text
                              fontSize=".9rem"
                              color="#0AA17C"
                              fontWeight="400"
                              aria-label={`total discount`}
                            >
                              {item?.discount} % de desconto

                            </Text>
                          )}
                        </Flex>

                        <Flex
                          w="70%"
                          gap="10px"
                        >
                          <Text
                            fontSize=".9rem"
                            color="newGray.400"
                            fontWeight="400"
                            aria-label={`Amount paind in ${index + 1} times`}
                          >
                            1x
                          </Text>
                          <Text
                            fontSize=".9rem"
                            color="black"
                            fontWeight="400"
                            aria-label={`Amount paind in ${index + 1} times`}
                          >
                            {formatCurrency({ value: array.installmentsToSeller[0]?.receivedAmount })}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                )}

                {creditCardTax === "EARLY_SELLER" && hasToogle === false && (
                  <Flex flexDir="column"
                    gap=".5rem">

                    {array?.installmentsToSeller?.map((item, index) => (
                      <Flex justifyContent="space-between"  >
                        <Flex gap=".5rem" w="130%" >
                          <Flex flexDirection="column">
                            <Flex gap="10px"  >
                              <Text
                                fontSize=".9rem"
                                color="newGray.400"
                                fontWeight="400"
                                aria-label={`Amount paind in ${index + 1} times`}
                              >
                                {item?.installments}x
                              </Text>
                              <Text
                                fontSize=".9rem"
                                color="black"
                                fontWeight="400"
                                aria-label={`Amount paind in ${index + 1} times`}
                              >
                                {formatCurrency({ value: array.installmentsToBuyer?.at(0)?.installmentAmount / (index + 1) })}
                              </Text>
                            </Flex>
                            <Text
                              fontSize=".9rem"
                              color="newGray.400"
                              fontWeight="400"
                              aria-label={`Amount paind in ${index + 1} times`}
                            >
                            </Text>

                          </Flex>
                          {item?.discount >= 0 && (
                            <Text
                              fontSize=".9rem"
                              color="#0AA17C"
                              fontWeight="400"
                              aria-label={`total discount`}
                            >
                              {item?.discount}% de desconto
                            </Text>
                          )}
                        </Flex>

                        <Flex w="70%" gap="10px" >
                          <Text
                            fontSize=".9rem"
                            color="newGray.400"
                            fontWeight="400"
                            aria-label={`Amount paind in ${index + 1} times`}
                          >
                            1x
                          </Text>
                          <Text
                            fontSize=".9rem"
                            color="black"
                            fontWeight="400"
                            aria-label={`Amount paind in ${index + 1} times`}
                          >
                            {formatCurrency({ value: item?.receivedAmount })}

                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                )}

                {creditCardTax === "EARLY_SELLER" && hasToogle === true && (
                  <Flex gap=".5rem">

                    <Flex
                      w='85%'
                      height="89px"
                      border="1px solid #EDF1F7"
                      borderRadius="2px"
                      alignContent="center"
                      alignItems="center"
                      paddingLeft="16px"
                      fontSize="14px"
                      gap='3px'
                    >
                      <Text
                        fontWeight="600"
                        color="black"
                      >
                        até
                      </Text>
                      <Text>
                        {array?.installmentsToSeller.length}x
                      </Text>
                    </Flex>
                    <Flex
                      w='30%'
                      height="89px"
                      alignContent="center"
                      alignItems="center"
                      paddingLeft="16px"
                    >
                      <Img w="37px" h="52px" src="/images/commons/ArrowToRight.svg" />

                    </Flex>
                    <Flex
                      w="85%"
                      height="89px"
                      border="1px solid #EDF1F7"
                      borderRadius="2px"
                      alignContent="center"
                      alignItems="center"
                      paddingLeft="16px"
                      gap="3px"
                    ><Text>
                        1x
                      </Text>
                      <Text
                        fontWeight="600"
                        color="black"
                      >
                        {formatCurrency({ value: array?.installmentsToSeller.at(-1)?.receivedAmount })}

                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )} */}
          {
            isPunctualCharge && (
              <Flex
                marginTop='-4px'
                backgroundColor="#FFFbe6"
                padding="14px 12px 12px 12px"
                border="1px solid #FFF1B8"
                color="#874D00"
                gap="10px"
                flexDirection="column"

              >
                <Text
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="21px"
                >
                  💡 A fim de otimizar suas taxas, selecione um parcelamento máximo que acredita que seu cliente irá usar
                </Text>


              </Flex>

              // <TitleSwitch
              //   anchoringState={anchoringState}
              //   setAnchoringState={setAnchoringState}
              //   creditCardType={creditCardType}
              //   tooltipText="
              // Utilizaremos o valor da parcela mais
              // alta como valor base da cobrança.
              // Todos os valores mais baixos aparecerão
              // com desconto sobre esse valor."
              //   text="Ancoragem de desconto"
              //   tooltipTextSwitch="
              //         Ancoragem só pode ser utilizada quando a opção cartão de Crédito Antecipado 
              //         com Taxas para o cliente estiver selecionada.
              //         "
              //   marginTop="1rem"
              //   justify="space-between"
              //   align="center"
              // />
            )
          }
        </>
      )}
    </>

  );
}
