import { Api } from "@/services";
import { Api_Banking } from "@/services/Api/bankingApiConfig";


export function useTransfers() {
  const baseUrl = "/sellers/transfers";
  const redeemsBaseUrl = "/redeems";
  const bankingBaseUrl = "transfers";
  const requestTransfer = (data: Object) => {
    return Api.post(`${baseUrl}`, data);
  };
  const requestAnticipationTransfer = () => {
    return Api.post(`${redeemsBaseUrl}/anticipation`)
  }
  const findSeller = () => {
    return Api.get(`${baseUrl}`);
  };
  const requestBankingTransfer = (data: Object) => {
    return Api_Banking.post(`${bankingBaseUrl}`, data);
  }
  const requestBankingConfirmationTransfer = (transfer_id: string) => {
    return Api_Banking.post(`${bankingBaseUrl}/confirmation/${transfer_id}`, {});
  }

  const findSellerTransferList = ({params}) => {
    return Api.get(`${baseUrl}/approve`, {
      params: {
        size: params.size,
        sort: params.sort,
      },
    })};
      return {
        requestAnticipationTransfer,
        requestTransfer,
        findSeller,
        findSellerTransferList,
        requestBankingTransfer,
        requestBankingConfirmationTransfer
      };
    }
