import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text, Switch, Flex, PinInput, HStack, PinInputField, background } from "@chakra-ui/react";
import { FirstStepIcon } from "@/styles/icons/FirstStepIcon";
import { SecondStepIcon } from "@/styles/icons/SecondStepIcon";
import { CopyIconFor2fa } from "@/styles/icons/CopyIconFor2fa";
import { ThirdStep } from "@/styles/icons/ThirdStep";
import { generateTwoFactor, enableTwoFactor, getTwoFactorStatus } from "./useSegurity";
import { QRCodeSVG } from 'qrcode.react';
import { InfoIconOutlineYellow } from "@/styles/icons/InfoIconOutlineYellow";
import { showToast } from "@/utils";



export function FirsStepModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(false);
    const [step, setStep] = useState<number>(1)
    const [pin, setPin] = useState("");
    const [urlQrCode, setUrlQrcode] = useState<string>("");
    const Token = localStorage.getItem("barte-auth-token")
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [status2FA, setStatus2FA] = useState<boolean>(false)



    async function getStatus2FA() {
        try {
            const response = await getTwoFactorStatus(Token)
            setStatus2FA(Boolean(response))
            console.log("response", response)

        } catch (error) {
            setStatus2FA(false)
        }
    }

    useEffect(() => {
        getStatus2FA()
    }, [])


    const handleToggle = (e) => {
        if (e.target.checked) {
            setIsTwoFaEnabled(true);
            onOpen();
        } if (status2FA === true && e.target.checked === false) {
            onOpen();
            setIsTwoFaEnabled(status2FA);
            setStep(4)
        }
        else {
            setIsTwoFaEnabled(false);
        }
    };

    useEffect(() => {
        setIsTwoFaEnabled(status2FA)
    }, [status2FA])

    async function getQRCode() {
        setLoading(true)
        try {
            const response = await generateTwoFactor(Token)
            console.log(response?.qrCodeUrl)
            console.log(response)
            if (response.qrCodeUrl !== null) {
                setUrlQrcode(response?.qrCodeUrl)
                setStep(prev => prev + 1)
            }


        } catch (error) {
            return error
        } finally {
            setLoading(false)
        }
    }

    async function sendPinTwoFa() {
        setLoading(true)

        try {
            const response = await enableTwoFactor(Token, pin, "true")
            if (response) {
                setIsTwoFaEnabled(true);
                onClose()
                setPin(null)
                setStep(1)
                setError(false)
                setErrorMessage("")
                showToast({
                    type: "success",
                    message: "Duplo fator de autenticação ativado.",
                });
            }
    
        } catch (error) {
            showToast({
                type: "error",
                message: "Falha ao ativar duplo fator de autenticação.",
            });
            setIsTwoFaEnabled(false);
            setErrorMessage("Código inválido")
            setError(true)
        } finally {
            setLoading(false)
            getStatus2FA()
        }
    }

    async function desative2FA() {
        setLoading(true)

        try {
            const response = await enableTwoFactor(Token, pin, "false")
            if (response) {
                setStep(1)
                setIsTwoFaEnabled(false);
                onClose();

            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const handleCloseModal = () => {
        setIsTwoFaEnabled(status2FA);
        setStep(1)
        onClose();
    };
    useEffect(() => {
        getStatus2FA()
    }, [])

    return (
        <>
            <Switch id="TwoFa" sx={{
                "& .chakra-switch__track": {
                    backgroundColor: "gray.300",
                },
                "& .chakra-switch__track[data-checked]": {
                    backgroundColor: "red.500",
                },
                "& .chakra-switch__thumb": {
                    backgroundColor: "white",
                }
            }} isChecked={isTwoFaEnabled} onChange={handleToggle} />

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay />
                {step !== 4 &&
                    <ModalContent gap="32px" p="24px" w="445px">
                        <ModalHeader
                            p="0"
                        >
                            <Text
                                color="black"
                                fontSize="20px"
                                fontWeight="600"
                                lineHeight="24.2px"

                                w="349px"
                            >
                                Configure Duplo Fator de Autenticação (2FA)

                            </Text>

                            <Text
                                mt="16px"
                                fontSize="12px"
                                fontFamily="Hero New"
                                fontWeight="400"
                                lineHeight="14.52px"
                                letterSpacing="-0.5px"
                                color=" rgba(0, 0, 0, 0.5)"
                            >
                                Complete a autenticação de dois fatores para aumentar sua segurança.
                            </Text>
                        </ModalHeader>
                        <ModalCloseButton
                            m="16px 16px"
                            w="19px"
                            h="19px"
                        />

                        {step === 1 &&
                            <ModalBody p="0" >
                                <Flex p="0" justifyContent="center" alignContent="center" alignItems="center" gap="8px">
                                    <Flex >


                                        <FirstStepIcon size={29} />
                                    </Flex>

                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        lineHeight="14.52px"
                                        color="black"

                                    >
                                        Caso ainda não possua, instale um  aplicativo de autenticação no seu celular. Algumas opções são: Google Authenticator, Duo, Microsoft Authenticator.
                                    </Text>

                                </Flex>
                            </ModalBody>
                        }

                        {step === 2 &&
                            <ModalBody p="0">
                                <Flex p="0" justifyContent="center" alignContent="center" alignItems="center" gap="8px">
                                    <Flex >


                                        <SecondStepIcon size={29} />
                                    </Flex>

                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        lineHeight="14.52px"
                                        color="black"

                                    >
                                        Abra o aplicativo de autenticação e utilize a câmera para ler o QR code abaixo ou insira o código manualmente
                                    </Text>
                                </Flex>
                                <Flex justifyContent="center" alignItems="center" mt="32px" gap="20px" flexDirection="column">

                                    <QRCodeSVG value={urlQrCode} />

                                    <Flex gap="8px" justifyContent="center" alignItems="center" h="18px" alignContent="center">
                                        {/* 
                                        <Text p="0" fontSize="14px" color="rgba(0, 0, 0, 1)" fontWeight="400" lineHeight="16.94px">
                                            1234 ABCD 5678 EFGH
                                        </Text>

                                        <CopyIconFor2fa size={18} /> */}
                                    </Flex>
                                </Flex>
                            </ModalBody>
                        }

                        {step === 3 &&
                            <ModalBody p="0 0 16px 0">
                                <Flex p="0" justifyContent="center" alignContent="center" alignItems="center" gap="8px">
                                    <Flex justifyContent="center" alignContent="center" alignItems="center" p="0" h="100%">
                                        <ThirdStep size={29} />
                                    </Flex>

                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        lineHeight="14.52px"
                                        color="black"

                                    >
                                        Digite o código de 6 dígitos gerados pelo aplicativo de autenticação
                                    </Text>
                                </Flex>
                                <Flex justifyContent="center" alignItems="center" mt="32px" flexDirection="column">
                                    <HStack

                                        spacing="8px"

                                        justifyContent="center">
                                        <PinInput
                                            type="number"
                                            value={pin}
                                            onChange={(value) => { setPin(value); setError(false); }}
                                            placeholder=""
                                            isInvalid={error} // Aplica erro a cada campo
                                            errorBorderColor="red.500" // Define a cor da borda de erro

                                        >
                                            <PinInputField

                                            />
                                            <PinInputField />
                                            <PinInputField />

                                            <PinInputField sx={{ "&:nth-of-type(4)": { marginLeft: "24px" } }} />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                    {error && <Text mb="-25px" fontSize="12px" color="red.500" mt={2}>{errorMessage}</Text>}
                                </Flex>
                            </ModalBody>
                        }

                        {step === 1 &&
                            <ModalFooter p="0">
                                <Button
                                    backgroundColor=" var(--Pink-100, rgba(223, 41, 93, 1))"
                                    color="white"
                                    borderRadius="2px"
                                    w="86px"
                                    h="32px"
                                    mr={3} onClick={getQRCode}
                                    fontWeight="400"
                                >

                                    Próximo
                                </Button>
                            </ModalFooter>
                        }
                        {step === 2 &&
                            <ModalFooter p="0">
                                <Button
                                    backgroundColor=" var(--Pink-100, rgba(223, 41, 93, 1))"
                                    color="white"
                                    borderRadius="2px"
                                    w="86px"
                                    h="32px"
                                    mr={3} onClick={() => setStep(prev => prev + 1)}
                                    fontWeight="400"
                                >

                                    Próximo
                                </Button>
                            </ModalFooter>

                        }
                        {step === 3 &&
                            <ModalFooter p="0">
                                <Button
                                    isLoading={loading}
                                    isDisabled={pin?.length < 6}
                                    backgroundColor=" var(--Pink-100, rgba(223, 41, 93, 1))"
                                    color="white"
                                    borderRadius="2px"
                                    w="86px"
                                    h="32px"
                                    mr={3} onClick={sendPinTwoFa}
                                    fontWeight="400"
                                >

                                    Enviar
                                </Button>
                            </ModalFooter>
                        }
                    </ModalContent>
                }
                {step === 4 &&
                    <ModalContent p="0" gap="8px" w="430px" h="258px" borderRadius="2px">
                        <Flex p="32px" gap="16px">
                            <Flex>

                                <InfoIconOutlineYellow size={24} />
                            </Flex>
                            <Flex flexDirection="column" gap="8px">

                                <ModalHeader
                                    p="0"
                                    fontSize="16px"
                                    color="rgba(0, 0, 0, 0.85)"
                                >Desativar Autenticação de dois fatores?</ModalHeader>
                                <ModalBody
                                    p="5px 0"
                                    fontSize="14px"
                                    fontWeight="400"
                                    color="rgba(0, 0, 0, 0.85)"
                                    // fontFamily="SF Pro Text"
                                    lineHeight="22px"
                                >
                                    <Text
                                        fontSize="14px"
                                        color="rgba(0, 0, 0, 0.85)"

                                    >

                                        Tem certeza que deseja desativar a autenticação de dois fatores?
                                    </Text>
                                    <Text
                                        fontSize="14px"
                                        color="rgba(0, 0, 0, 0.85)"
                                    >

                                        Você irá perder uma camada de proteção da sua conta contra acessos não autorizados.
                                    </Text>
                                </ModalBody>
                                <ModalFooter w="100%" p="0">
                                    <Flex gap="8px" p="16px 0 0 0"
                                    >
                                        <Button
                                            onClick={onClose}
                                            isLoading={loading}
                                            type="submit"
                                            h="32px"
                                            borderRadius="2px"
                                            variant="outline"
                                            w="89px"
                                            _hover={{ opacity: "0.7" }}
                                            fontWeight="400"
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            onClick={desative2FA}
                                            isLoading={loading}
                                            type="submit"
                                            h="32px"
                                            borderRadius="2px"
                                            variant="solid"
                                            w="93px"
                                            color="white"
                                            backgroundColor="var(--Yellow-400, rgba(198, 138, 49, 1))"
                                            _hover={{ opacity: "0.7" }}
                                            fontWeight="400"

                                        >
                                            Desativar
                                        </Button>
                                    </Flex>
                                </ModalFooter>
                            </Flex>
                        </Flex>
                    </ModalContent>
                }
            </Modal>
        </>
    );
}
