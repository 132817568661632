import { CloseIcon } from "@/styles/icons/CloseIcon";
import { format } from "date-fns";
import { useEffect } from "react";
import { Container } from "./styles";

export interface ChargeTriesProps {
  formattedValue: string;
  errorTransactionalResponse: any;
  errorTimestamp: string;
  expirationDate: string;
  formattedExpirationDate: string;
  status: string;
  uuid: string;
  value: number;
}

interface ErrorModalProps {
  confirmButtonText?: string;
  status: string;
  clientName: string;
  modalState: boolean;
  setModalState: (item: boolean) => void;
  error: ChargeTriesProps;
}

export function ErrorModal({
  confirmButtonText,
  clientName,
  status,
  modalState,
  setModalState,
  error
}: ErrorModalProps) {

  function handleConcludeButton() {
    setModalState(!modalState);
  }

  function handleFilterChargeTryTransactionalError(flag: string): string {
    if (error?.errorTimestamp && flag === "timestamp") {
      const time = new Date(error?.errorTimestamp);
      return format(time, "dd/MM/yyyy hh:mm");
    }
    if (error?.errorTransactionalResponse && "errorReason") {
      if (error?.errorTransactionalResponse?.length === 0) {
        return "Sem dados de erro"
      }
      const arrFormattedJSON = JSON?.parse(error?.errorTransactionalResponse)
      const arrJSONError = arrFormattedJSON?.map(arr => JSON?.parse(arr['error']))
      const errorNumber = arrJSONError[0]?.codeReason ?? ""
      const errorReason = arrJSONError[0]?.message ?? ""
      return `${errorNumber} = ${errorReason} (${errorNumber})`
    }
    return "null"
  }
  // const arrFormattedJSON = JSON.parse("[{\"provider\":\"PAGARME\",\"status\":\"failed\",\"error\":\"{\\\"codeReason\\\":\\\"1015\\\", \\n\\\"declinedCode\\\":\\\"card_not_supported\\\", \\n\\\"message\\\":\\\"Função não suportada\\\", \\n\\\"retryable\\\":true}\",\"fraudAnalysis\":\"null\"},{\"provider\":\"PAGARME\",\"status\":\"failed\",\"error\":\"{\\\"codeReason\\\":\\\"1015\\\", \\n\\\"declinedCode\\\":\\\"card_not_supported\\\", \\n\\\"message\\\":\\\"Função não suportada\\\", \\n\\\"retryable\\\":true}\",\"fraudAnalysis\":\"null\"}]")
  // const arrJSONError = arrFormattedJSON.map(arr => JSON.parse(arr['error']))
  // console.log(arrFormattedJSON, arrJSONError)
  useEffect(() => {
    handleFilterChargeTryTransactionalError("");
  }, [error?.errorTimestamp]);



  useEffect(() => {
    console.log({
      confirmButtonText,
      clientName,
      status,
      modalState,
      error
    })
  }, [confirmButtonText,
    clientName,
    status,
    modalState,
    error])



  return (
    <Container isOpen={modalState}>

      <div>
        <div className="box">

          <div className="top">
            <p>
              Tentativa de cobrança
            </p>
            <button onClick={handleConcludeButton}>
              <CloseIcon size={11} />
            </button>
          </div>

          <div className="middle">
            <div>
              <div className="client">
                <p>Cliente</p>
                <p>{clientName}</p>
              </div>

              <div className="error">
                <p>Status</p>
                <div>
                  <p>{status}</p>
                </div>
              </div>
            </div>
            <div>
              <div className="charge-amount">
                <p>Valor da cobrança</p>
                <p>{error?.formattedValue}</p>
              </div>

            </div>
          </div>

          <div className="try">
            <div >
              <div>
                <p>Data da tentativa</p>
                <p>{handleFilterChargeTryTransactionalError("timestamp")}</p>
              </div>
              <div>
                <p>Informação do erro</p>
                <p>{handleFilterChargeTryTransactionalError("errorReason")}</p>
              </div>
            </div>
          </div>

          <div className="footer">
            <button onClick={handleConcludeButton}>
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}