import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Flex, Text, Box, Image, Link, Heading, Badge } from "@chakra-ui/react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { WithAuth } from "@/hoc";
import { ClientData } from "@/models";
import { formatCurrency, formatDateAndHour, showToast } from "@/utils";
import CreditCardManagement from "./_CreditCardManagement";
import { useCreditCard } from "@/hooks/useCreditCard";
import styles from '../../../styles/global.scss';
const CreditCardPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getInitialCreditCardData, creditCardTransactionsData } = useCreditCard();
  const { id } = useParams();
  const tabs = [
    {
      tab: "Fatura",
      key: "ALL",
      status: "ALL",
    },
  ];
  async function getInitialData() {
    setLoading(true)
    try {
      await getInitialCreditCardData(id)
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getInitialData();
  }, []);

  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const columns: ColumnsType<any[]> = [
    {
      key: "createdAt",
      title: "Lançamento",
      dataIndex: "createdAt",
      render: (createdAt) => (
        <Flex
          direction="column"
        >
          <Text 
            fontSize="md" 
            fontWeight="bold"
          >
            {formatDateAndHour(createdAt)?.formattedDate}
          </Text>
          <Text 
            fontSize="sm" 
            color="gray.500"
          >
            {formatDateAndHour(createdAt)?.formattedTime}
          </Text>
        </Flex>
      ),
    },
    {
      key: "merchantName",
      title: "Operação",
      dataIndex: "merchantName",
    },
    {
      key: "",
      title: "",
      dataIndex: "",
    },
    {
      key: "",
      title: "",
      dataIndex: "",
    },
    {
      key: "billingAmount",
      title: "Valor",
      dataIndex: "billingAmount",
      render: (billingAmount) => (
        <Flex align="center" gridGap="2">
          <Text>{formatCurrency({value: billingAmount})}</Text>
        </Flex>
      ),
    },
    {
      key: "approved",
      title: "Status",
      dataIndex: "approved",
      render: (approved) => (
        <Flex align="center" gridGap="2">
          <Badge
            variant={approved ? "greenBills" : "redBills"}
          >
            {approved ? "Aprovada": "Não aprovada"}
          </Badge>
        </Flex>
      ),
    },
  ];

  return (
    <Flex 
        flexDir="column"
        width="100%"
        gap="1rem"
    >
      <Flex 
        align="center"
        justify="space-between"
      >
        <Heading
            fontSize="1.5rem"
        >
            Cartão de crédito
        </Heading>
        <Button
            variant="secondary"
            size="sm"
            borderRadius=".5rem"
            fontWeight={400}
            transition="filter .2s"
            _hover={{
            transition: "filter .2s",
            filter: "brightness(.9)",
            }}
            _focus={{}}
            onClick={() => navigate("/gerenciar-cartoes")}
        >
          Gerenciar cartões
        </Button>
      </Flex>
      <CreditCardManagement

      />
      <Tabs 
        defaultActiveKey="1" 
        className="tabs-custom"
      >
        {tabs.map(({ key, tab }) => (
          <TabPane tab={tab} key={key}>
            <Table<any>
              loading={loading}
              columns={columns as ColumnsType}
              dataSource={creditCardTransactionsData}
              rowKey={(record) => record.id}
              locale={{ emptyText: "Sem dados da fatura" }}
              pagination={false}
              //@ts-ignore
              className={styles.customTable}
            />
          </TabPane>
        ))}
      </Tabs>
    </Flex>
  );
};

export default WithAuth(CreditCardPage);
