import { PlusCircle } from "@/styles/icons";
import {
    Button,
    Modal,
    ModalProps,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Flex,
  } from "@chakra-ui/react";
  
  interface FormModalProps extends ModalProps {
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: () => void;
    handleConfirmModal: () => void;
    title: string;
    confirmationButtonText?: string;
    disableWhenIsFormEmpty?: boolean;
    type?: "ADD" | "EDIT" | "DELETE"
  }
  
  const FormModal2: React.FC<FormModalProps> = ({
    isOpen,
    isSubmitting,
    onClose,
    handleConfirmModal,
    children,
    isCentered = true,
    title,
    confirmationButtonText = "Adicionar",
    disableWhenIsFormEmpty = false,
    type,
  }) => {
    function formatIconType(type) {
      const iconTypes = {
        ADD: <PlusCircle />,
        EDIT: <></>,
        DELETE: <></>,
      }
      return iconTypes[type] || <></>;
    }
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleConfirmModal} >
          <ModalHeader>{formatIconType} {title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody 
            p="24px" 
            borderTop="1px solid" 
            borderBottom="1px solid" 
            borderColor="gray.50"
          >
            {children}
          </ModalBody>
          <ModalFooter>
            <Flex 
                w="100%"
                justifyContent="right"
                gap={4}
            >
                <Button 
                    variant="cancel2"
                    onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button 
                    id={confirmationButtonText === "Adicionar" ? "recebimento_manual" : ""}
                    variant="primary2"
                    type="submit" 
                    isDisabled={isSubmitting || disableWhenIsFormEmpty}
                    isLoading={isSubmitting}
                    _disabled={{
                      bgColor: "neutral.3",
                      border: "1px solid",
                      borderColor: "neutral.200",
                      cursor: "not-allowed",
                      color: "rgba(0, 0, 0, 0.25)",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleConfirmModal();
                    }}
                >
                { confirmationButtonText }
                </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  export { FormModal2 }