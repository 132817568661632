import { Flex, Text, Switch } from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { FirsStepModal } from "./_StepModal";
import { getTwoFactorStatus } from "./useSegurity";
import { useEffect, useState } from "react";



const Segurity = () => {
    const Token = localStorage.getItem("barte-auth-token")
    const [status2FA, setStatus2FA] = useState<boolean>(false)


    async function getStatus2FA() {
        try {
            const response = await getTwoFactorStatus(Token)
            setStatus2FA(Boolean(response))
            console.log("response", response)

        } catch (error) {
            setStatus2FA(false)
        }
    }

    useEffect(() => {
        getStatus2FA()
    }, [])

    return (
        <Flex gap="42px" flexDirection="column">
            <Text
                fontSize="25px"
                fontWeight="600"
                lineHeight="30.26px"
                color="black"

            >
                Segurança
            </Text>

            <Flex gap="15px" flexDirection="column">

                <Flex gap="8px">
                    <Text
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="19.36px"
                        color="black"
                    >Duplo fator de autenticação
                    </Text>
                    <FirsStepModal  />
                </Flex>
                <Text
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="16.94px"
                    color="rgba(0, 0, 0, 0.5)"
                    w="397px"
                    letterSpacing="-1px"
                >
                    Ative a autenticação de dois fatores para proteger sua conta contra acessos não autorizados e aumentar sua segurança
                </Text>
            </Flex>
        </Flex>
    )
}
export default WithAuth(Segurity);