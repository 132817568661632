
import { IconProps } from "@/models";

export function LockIcon({
  size = 24,
  color = "#000",
  opacity = "1",
}: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 11.375H17.9062V6.125C17.9062 4.46797 16.5633 3.125 14.9062 3.125H9.09375C7.43672 3.125 6.09375 4.46797 6.09375 6.125V11.375H4.5C4.08516 11.375 3.75 11.7102 3.75 12.125V21.125C3.75 21.5398 4.08516 21.875 4.5 21.875H19.5C19.9148 21.875 20.25 21.5398 20.25 21.125V12.125C20.25 11.7102 19.9148 11.375 19.5 11.375ZM7.78125 6.125C7.78125 5.40078 8.36953 4.8125 9.09375 4.8125H14.9062C15.6305 4.8125 16.2188 5.40078 16.2188 6.125V11.375H7.78125V6.125ZM18.5625 20.1875H5.4375V13.0625H18.5625V20.1875ZM11.3438 16.9297V18.1719C11.3438 18.275 11.4281 18.3594 11.5312 18.3594H12.4688C12.5719 18.3594 12.6562 18.275 12.6562 18.1719V16.9297C12.8497 16.7908 12.9941 16.5941 13.0687 16.3679C13.1432 16.1417 13.1441 15.8977 13.0711 15.6709C12.9982 15.4442 12.8552 15.2465 12.6627 15.1062C12.4702 14.966 12.2382 14.8904 12 14.8904C11.7618 14.8904 11.5298 14.966 11.3373 15.1062C11.1448 15.2465 11.0018 15.4442 10.9289 15.6709C10.8559 15.8977 10.8568 16.1417 10.9313 16.3679C11.0059 16.5941 11.1503 16.7908 11.3438 16.9297Z" fill="black" fill-opacity="0.85"/>
    </svg>
  );
}
