const BASE_URL = import.meta.env.VITE_API_URL;

interface ApiError {
    status: number;
    message: string;
}

interface TwoFactorResponse {
    qrCodeUrl?: string;
    message?: string;
    success?: boolean;
    status?: number;
}

async function apiFetch<T>(endpoint: string, options: RequestInit): Promise<T> {
    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, options);

        const contentType = response.headers.get("content-type");

        if (!response.ok) {
            const errorMessage = contentType?.includes("application/json")
                ? (await response.json()).message || "Erro desconhecido"
                : "Erro ao acessar API";
                
            throw { status: response.status, message: errorMessage };
        }

        if (response.status === 204 || !contentType || !contentType.includes("application/json")) {
            return {} as T; 
        }

        return (await response.json()) as T;
    } catch (error: any) {
        console.error("Erro na requisição:", error);

        throw error.status ? error : { status: 500, message: "Erro de conexão com o servidor" };
    }
}

export async function generateTwoFactor(token: string): Promise<TwoFactorResponse> {
    return await apiFetch<TwoFactorResponse>("twofactor/generate", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
}

export async function enableTwoFactor(token: string, code: string, active: string = "true"): Promise<TwoFactorResponse> {
    return await apiFetch<TwoFactorResponse>(`twofactor/enabled?code=${code}&enabled=${active}`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}` },
    });
}

export async function getTwoFactorStatus(token: string): Promise<TwoFactorResponse> {
    return await apiFetch<TwoFactorResponse>("twofactor/status", {
        method: "GET",
        headers: { "Authorization": `Bearer ${token}` },
    });
}
