import { useCreditCard } from "@/hooks/useCreditCard";
import { LogoBarteCreditCard } from "@/styles/icons/LogoBarteCreditCard";
import { MastercardLogoCreditCard } from "@/styles/icons/MastercardLogoCreditCard";
import { formatCurrency } from "@/utils";
import { Box, Flex, Text, Badge, SkeletonText } from "@chakra-ui/react";

interface CreditCardProps {
  isLimitProgressVisible?: boolean;
  currentBalance: number;
  availableLimit: number;
  usedPercentage: number;
  title: string;
}

export function CreditCardInvoice({
    isLimitProgressVisible = false,
    currentBalance,
    availableLimit,
    usedPercentage,
    title,
}: CreditCardProps) {
  const { creditCardLoading, initialDataIsLoading } = useCreditCard();
  return (
    <Flex
        maxW="400px"
        w="100%"
        border="1px solid #E6E9F0"
        borderRadius="1rem"
        padding="1rem"
        minH="185px"
        direction="column"
        justifyContent="center"
    >
        <Flex 
            justify="space-between" 
            align="center" 
            mb={4}

        >
            <Flex 
                align="center" 
                gap={4}
                w="100%"
            >
            <Text fontWeight="medium">{title}</Text>
            {/* <Text fontSize="sm" color="gray.500">
                Fatura fecha em {nextClosingDate}
            </Text> */}
            </Flex>
            <Flex 
                align="center" 
                gap={4}
                w="100%"
            >
            {/* <Text fontSize="sm" color="gray.500">
                Venc. {dueDate}
            </Text>
            <Button size="sm" colorScheme="pink">
                Antecipar pagamento
            </Button> */}
            </Flex>
        </Flex>
        <Box>
            <Flex 
                justify="space-between" 
                align="center"
            >
                <SkeletonText 
                    isLoaded={!initialDataIsLoading} 
                    noOfLines={1} 
                    skeletonHeight="4" 
                    borderRadius="1rem"
                    mb="2"
                >
                    <Text fontSize="2xl" fontWeight="semibold">
                    {formatCurrency({value: currentBalance})}
                    </Text>
                </SkeletonText>
                {isLimitProgressVisible && (
                    <SkeletonText 
                        isLoaded={!initialDataIsLoading} 
                        noOfLines={1} 
                        skeletonHeight="4" 
                        borderRadius="1rem"
                        mb="2"
                    >
                        <Text fontSize="sm" color="gray.500">
                        Limite disponível {formatCurrency({value: availableLimit})}
                        </Text>
                    </SkeletonText>
                )}
            </Flex>
            {isLimitProgressVisible && (
                <Flex
                    w="100%"
                    h={"14px"}
                    bg="linear-gradient(to right, #e91e63, #ff5370)"
                    rounded="full"
                    overflow="hidden"
                >
                {/* Indicador */}
                <Box
                    w={`${usedPercentage}%`}
                    h="100%"
                    bg="#3a1522"
                    rounded="full"
                    transition="width 0.3s ease-in-out"
                />
                </Flex>
            )}
        </Box>
    </Flex>

  );
}