import { useEffect, useState } from "react";
import { Button, Flex, Heading, Link, Spacer, Stack, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useClient, useRegister } from "@/hooks";
import { Input, Checkbox } from "@/components";
import { clearMask, formatPixKeyMasks, formatPixKeyPlaceholders, showToast } from "@/utils";
import { BannerLateral } from "@/layouts";
import * as z from "zod";
import { StepComponent } from "@/components/Forms/StepComponent";
import { SelectButtonV2 } from "@/components/Forms/SelectButtonV2";
import { Banks } from "@/models/Banks";
import { cnpj } from "cpf-cnpj-validator";
const companyFormSchema = z.object({
  cnpj: z.string().min(14, "CNPJ inválido"),
  companyName: z.string().min(2, "Razão social é obrigatória"),
  fantasyName: z.string().min(2, "Nome fantasia é obrigatório"),
  email: z.string().email("Email inválido"),
  sellerUrl: z.string().min(1, "Website é obrigatório").refine((value) => {
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlPattern.test(value);
  }, "Url no formato inválido."),
  contactPhone: z.string().min(10, "Celular inválido"),
  contactName: z.string().min(2, "Nome do contato é obrigatório"),
  officePhone: z.string().optional(),
  password: z.string()
    .min(8, 'A senha precisa ter no mínimo 8 caracteres')
    .max(30, 'A senha precisa ter no máximo 30 caracteres')
    .nonempty("O campo senha é obrigatório!"),
  confirm_password: z.string()
  .nonempty("O campo confirme sua senha é obrigatório!"),
  privacyPolicy: z.boolean()
    .refine((data) => data === true, {
      message: "O aceite de política de privacidade é obrigatório!",
    }),
  userTerms: z.boolean()
    .refine((data) => data === true, {
      message: "O aceite de termos de uso é obrigatório!",
    }),
})
.refine((data) => data.password === data.confirm_password, {
  message: "As senhas precisam ser iguais",
  path: ["confirm_password"],
});

const addressFormSchema = z.object({
  zipCode: z.string().min(8, "CEP inválido"),
  street: z.string().min(3, "Rua é obrigatória"),
  number: z.string().min(1, "Número é obrigatório"),
  complement: z.string().optional(),
  district: z.string().min(2, "Bairro é obrigatório"),
  city: z.string().min(2, "Cidade é obrigatória"),
  state: z.string().min(2, "Estado é obrigatório"),
})

const bankingFormSchema = z.object({
  bank: z.string().min(1, "Banco é obrigatório"),
  agency: z.string().min(1, "Agência é obrigatória"),
  agencyDigit: z.string().min(1, "Digito da agência é obrigatória").max(1, "O digito da agência deve ser entre 0 e 9."),
  account: z.string().min(1, "Conta é obrigatória"),
  accountDigit: z.string().min(1, "Digito da conta é obrigatória").max(1, "O digito da conta deve ser entre 0 e 9."),
  accountType: z.string().min(1, "Tipo de conta é obrigatório"),
  pixKeyType: z.string().min(1, "Tipo de chave PIX é obrigatório"),
  pixKey: z.string().optional(),
}).superRefine((arg, ctx) => {
  const { pixKeyType, pixKey } = arg;
  if (pixKeyType !== "ALLEATORY_KEY" && !pixKey) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Chave PIX é obrigatória",
      path: ["pixKey"],
    });
  }
  if (pixKeyType === "DOCUMENT") {
    if (!cnpj.isValid(pixKey)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "CNPJ deve ser válido",
        path: ["pixKey"],
      });
    }
  } else if (pixKeyType === "EMAIL") {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(pixKey)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "E-mail inválido",
        path: ["pixKey"],
      });
    }
  }
})

type CompanyFormValues = z.infer<typeof companyFormSchema>
type AddressFormValues = z.infer<typeof addressFormSchema>
type BankingFormValues = z.infer<typeof bankingFormSchema>

const Register: React.FC = () => {
  const { registerSellerV2 } = useRegister();
  const { consultCep } = useClient();
  const [ip, setIP] = useState("");
  const navigate = useNavigate();
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const stepItems = [
    {
        id: 1,
        title: "Dados da empresa",
    },
    {
        id: 2,
        title: "Endereço",
    },
    {
        id: 3,
        title: "Dados bancários",
    },
  ]
  const [isLoading, setIsLoading] = useState(false);
  const [isCepLoading, setIsCepLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    address: {} as AddressFormValues,
  })

  useEffect(() => {
      getData();
  }, []);
  const companyForm = useForm<CompanyFormValues>({
    resolver: zodResolver(companyFormSchema),
    shouldFocusError: false,
  })

  const addressForm = useForm<AddressFormValues>({
    resolver: zodResolver(addressFormSchema),
    shouldFocusError: false,
  })

  const bankingForm = useForm<BankingFormValues>({
    resolver: zodResolver(bankingFormSchema),
    shouldFocusError: false,
  })


  async function handleGetCep() {
      const cep = addressForm.watch("zipCode")
      try {
          addressForm.clearErrors("zipCode")
          setIsCepLoading(true);
          const { data } = await consultCep(clearMask(cep));
          addressForm.setValue("street", data.logradouro);
          addressForm.setValue("district", data.bairro);
          addressForm.setValue("city", data.localidade);
          addressForm.setValue("state", data.uf);
          addressForm.setValue("complement", data.complemento);
      } catch {
          if (cep.length !== 10) return addressForm.setError("zipCode", { type: "custom", message: "Cep inválido." })
      } finally {
          setIsCepLoading(false);
      }
  }
  function onCompanySubmit(data: CompanyFormValues) {
      const hostNameArr = window.location?.host?.split('.');
      hostNameArr.shift();
      const formatData = {
          cnpj: clearMask(data.cnpj),
          document: clearMask(data.cnpj),
          companyName: data.companyName,
          fantasyName: data.fantasyName,
          domainUrl: hostNameArr?.join(".") ?? "barte.com",
          sellerUrl: data.sellerUrl,
          userAdmin: {
              email: data.email,
              password: data.password
          },
          contact: {
              name:  data.contactName,
              email:  data.email,
              countryCode: "+55",
              phone:  clearMask(data.contactPhone)
          },         
          acceptTerm: {
            ip: ip,
            dateTime: new Date().toISOString(),
            userTerms: data.userTerms,
            cookiePolicy: data.privacyPolicy,
            privacyPolicy: data.privacyPolicy,
        },
      }
    setFormData((prev) => ({ ...prev, ...formatData }))
    setCurrentStep(2)
  }

  function onAddressSubmit(data: AddressFormValues) {
      const formatData = {
          zipCode: clearMask(data.zipCode),
          street: data.street,
          number: data.number,
          complement: data.complement,
          district: data.district,
          city: data.city,
          state: data.state,
          country: "BR",
      }
      setFormData((prev) => ({ ...prev, address: formatData }))
      setCurrentStep(3)
  }

  async function onBankingSubmit(data: BankingFormValues) {
      setFormData((prev) => ({ ...prev, banking: data }))
      const finalData = {
          ...formData,
          account: {
              account: {
                  bank: data.bank,
                  issuer: data.agency,
                  issuerDigit: data.agencyDigit,
                  number: data.account,
                  bankDigit: data.accountDigit,
                  accountType: data.accountType
              },
              pixKey: data.pixKeyType === "EMAIL" ?  data.pixKey : clearMask(data.pixKey),
              pixKeyType: data.pixKeyType
          },
      }
      if(data.pixKeyType === "ALLEATORY_KEY") {
        delete finalData.account.pixKey;
      }
      try{
          setIsLoading(true);
          await registerSellerV2(finalData)
          showToast({
              message: "Cadastro criado com sucesso!",
              type: "success",
          });
          navigate(`/login`)
      } catch (error) {
          //eslint-disable-next-line
          error?.response?.data?.errors.map((error) => {
              showToast({
                type: "error",
                message: `${error?.code} - ${error?.description}`
              })
          });
      } finally {
          setIsLoading(false);
      }
  }

  const pixKeyType = bankingForm.watch("pixKeyType")
  useEffect(() => {
    bankingForm.clearErrors("pixKey");
    bankingForm.setValue("pixKey", "");
  }, [pixKeyType])

  return (
    <Flex 
      flexDir={["column", "column", "column", "row"]} 
      h="100vh"
    >
      <Flex
        align="center"
        w={["100%", "100%", "100%", "50%"]}
        justify="center"
        overflow="auto"
      >
          <Flex
            direction="column"
            justify="center"
            pt="180px"
            pb="30px"
            gap="6"
          >
            <img src={`/images/commons/logo-${import.meta.env.VITE_APPLICATION_NAME}.svg`} width="180" height="60" />
            <Flex w="100%" mb="20px">
              <Heading as="h3" fontSize="31px" w="60%">
                Criar Conta
              </Heading>
              <Flex
                fontSize="14px"
                color="gray.400"
                w="50%"
                flexDirection="column"
                justify="flex-start"
                align="flex-end"
              >
                Já possui uma conta?
                <Link fontSize="14px" href="/login" textDecoration="underline">
                  Fazer login
                </Link>
              </Flex>
            </Flex>
            <Stack w="100%">
              <Flex
                flexDir="column"
                w="100%"
                gap="4"
              >
                  <StepComponent
                      section={currentStep}
                      items={stepItems}
                  />
                  {currentStep === 1 && (
                      <form 
                        onSubmit={companyForm.handleSubmit(onCompanySubmit)}
                      >
                      <VStack 
                        spacing={2}
                      >
                          <Input
                              label="CNPJ"
                              type="text"
                              name="cnpj"
                              placeholder="01.123.456/0001-99"
                              mask="cnpj"
                              register={companyForm.register("cnpj")}
                              errors={companyForm.formState.errors.cnpj}
                              noMarginOnError
                          />
                          <Flex
                              w="100%"
                              gap="4"
                          >
                              <Input 
                                  label="Razão social"
                                  type="text"
                                  name="companyName"
                                  placeholder="Ultra Bikes LTDA"
                                  register={companyForm.register("companyName")}
                                  errors={companyForm.formState.errors.companyName}
                                  noMarginOnError
                              />

                              <Input 
                                  label="Nome Fantasia"
                                  type="text"
                                  name="fantasyName"
                                  placeholder="Ultra Bikes"
                                  register={companyForm.register("fantasyName")}
                                  errors={companyForm.formState.errors.fantasyName}
                                  noMarginOnError
                              />
                          </Flex>
                          <Flex
                              w="100%"
                              gap="4"
                          >
                              <Input 
                                  label="Email"
                                  type="email"
                                  name="email"
                                  placeholder="nome@empresa.com.br"
                                  register={companyForm.register("email")}
                                  errors={companyForm.formState.errors.email}
                                  noMarginOnError
                              />
                              <Input 
                                  label="Website"
                                  type="text"
                                  name="sellerUrl"
                                  placeholder="https://www.nomeempresa.com"
                                  register={companyForm.register("sellerUrl")}
                                  errors={companyForm.formState.errors.sellerUrl}
                                  noMarginOnError
                              />
                          </Flex>
                          <Input 
                              label="Nome do contato"
                              type="text"
                              name="contactName"
                              placeholder="Joana Barbosa"
                              register={companyForm.register("contactName")}
                              errors={companyForm.formState.errors.contactName}
                              noMarginOnError
                          />
                          <Flex
                              w="100%"
                              gap="4"
                          >
                              <Input 
                                  label="Celular de contato"
                                  type="text"
                                  name="contactPhone"
                                  placeholder="11 99999 9999"
                                  register={companyForm.register("contactPhone")}
                                  mask="cellphone"
                                  errors={companyForm.formState.errors.contactPhone}
                                  noMarginOnError
                              />

                              <Input 
                                  label="Telefone (opcional)"
                                  type="text"
                                  name="officePhone"
                                  placeholder="11 3222 2222"
                                  register={companyForm.register("officePhone")}
                                  mask="cellphone"
                                  errors={companyForm.formState.errors.officePhone}
                                  noMarginOnError
                              />
                          </Flex>
                          <Input
                            label="Senha"
                            type="password"
                            name="password"
                            placeholder="Sua senha"
                            register={companyForm.register("password")}
                            errors={companyForm.formState.errors.password}
                            aria-label="Digite sua senha"
                            aria-invalid={companyForm.formState.errors.password ? "true" : "false"}
                            fontSize="1rem"
                            labelColor="black"
                            mb="10px"
                            noMarginOnError
                          />

                          <Input
                            label="Confirme sua senha"
                            type="password"
                            name="confirm_password"
                            placeholder="Sua senha"
                            register={companyForm.register("confirm_password") }
                            errors={companyForm.formState.errors.confirm_password}
                            aria-label="Digite sua senha"
                            aria-invalid={companyForm.formState.errors.confirm_password ? "true" : "false"}
                            fontSize="1rem"
                            labelColor="black"
                            mb="10px"
                            noMarginOnError
                          />
                          <Flex
                            flexDir="column"
                            w="100%"
                          >
                            <Checkbox
                              size="md"
                              fontSize="1rem"
                              textSize="1rem"
                              name="privacyPolicy"
                              register={companyForm.register("privacyPolicy")}
                              errors={companyForm.formState.errors?.privacyPolicy}
                              colorScheme="pink"
                              text={[
                                "Concordo com a ",
                                <a
                                  href="https://www.barte.com/politica-de-privacidade"
                                  title="Política de Privacidade"
                                  target="_blank"
                                >
                                  Política de Privacidade
                                </a>,
                              ]}
                            />
                            <Checkbox
                              size="md"
                              fontSize="1rem"
                              textSize="1rem"
                              name="userTerms"
                              colorScheme="pink"
                              register={companyForm.register("userTerms")}
                              text={[
                                "Concordo com os ",
                                <a
                                  href="https://www.barte.com/termos-de-uso"
                                  title="Termos de Uso"
                                  target="_blank"
                                >
                                  Termos de Uso
                                </a>,
                              ]}
                              errors={companyForm.formState.errors?.userTerms}
                            />
                          </Flex>

                          <Flex 
                              w="100%" 
                          >
                              <Button 
                                  type="submit"
                                  variant="outline"
                                  onClick={() => navigate(-1)}
                              >
                                  Voltar
                              </Button>
                              <Spacer />
                              <Button 
                                  type="submit" 
                                  colorScheme="pink"
                              >
                                  Próximo
                              </Button>
                          </Flex>
                      </VStack>
                      </form>
                  )}
                  {currentStep === 2 && (
                      <form onSubmit={addressForm.handleSubmit(onAddressSubmit)}>
                        <VStack 
                          spacing={2}
                        >
                            <Input
                                label="CEP"
                                type="text"
                                name="zipCode"
                                placeholder="00000-000"
                                mask="cep"
                                register={addressForm.register("zipCode")}
                                errors={addressForm.formState.errors.zipCode}
                                onBlur={handleGetCep}
                                noMarginOnError
                            />

                            <Input
                                label="Rua"
                                type="text"
                                name="street"
                                placeholder="Avenida Vitória"
                                register={addressForm.register("street")}
                                errors={addressForm.formState.errors.street}
                                isDisabled={isCepLoading}
                                noMarginOnError
                            />

                            <Flex
                              w="100%"
                              gap="4"
                            >
                                <Input
                                    label="Número"
                                    type="text"
                                    name="number"
                                    placeholder="1234"
                                    register={addressForm.register("number")}
                                    errors={addressForm.formState.errors.number}
                                    isDisabled={isCepLoading}
                                    noMarginOnError
                                />
                                <Input
                                    label="Complemento (opcional)"
                                    type="text"
                                    name="complement"
                                    placeholder="Sala 12"
                                    register={addressForm.register("complement")}
                                    errors={addressForm.formState.errors.complement}
                                    isDisabled={isCepLoading}
                                    noMarginOnError
                                />
                            </Flex>
                            <Input
                                label="Bairro"
                                type="text"
                                name="district"
                                placeholder="Centro"
                                register={addressForm.register("district")}
                                errors={addressForm.formState.errors.district}
                                isDisabled={isCepLoading}
                                noMarginOnError
                            />
                            <Flex
                              w="100%"
                              gap="4"
                            >
                                <Input
                                    label="Cidade"
                                    type="text"
                                    name="city"
                                    placeholder="Centro"
                                    register={addressForm.register("city")}
                                    errors={addressForm.formState.errors.city}
                                    noMarginOnError
                                />
                                <Input
                                    label="Estado"
                                    type="text"
                                    name="state"
                                    placeholder="SP"
                                    register={addressForm.register("state")}
                                    errors={addressForm.formState.errors.state}
                                    isDisabled={isCepLoading}
                                    noMarginOnError
                                />
                            </Flex>

                            <Flex w="100%" justify="space-between">
                            <Button variant="outline" onClick={() => setCurrentStep(1)}>
                                Voltar
                            </Button>
                            <Button type="submit" colorScheme="pink">
                                Próximo
                            </Button>
                            </Flex>
                        </VStack>
                      </form>
                  )}
                  {currentStep === 3 && (
                      <form onSubmit={bankingForm.handleSubmit(onBankingSubmit)}>
                      <VStack spacing={2}>
                          <Flex 
                              w="100%" 
                              gap={4}
                          >
                              <SelectButtonV2
                                  name="bank"
                                  label="Banco"
                                  variant="ghost"
                                  bgColor="#EDF1F7"
                                  h="27px"
                                  register={{ ...bankingForm.register("bank") }}
                                  errors={bankingForm.formState.errors.bank}
                              >
                                  {Banks.map(bank => <option value={bank.value}>{bank.label}</option>)}
                              </SelectButtonV2>
                              <SelectButtonV2
                                  name="accountType"
                                  label="Tipo de conta"
                                  variant="ghost"
                                  bgColor="#EDF1F7"
                                  h="27px"
                                  register={{ ...bankingForm.register("accountType") }}
                                  errors={bankingForm.formState.errors.accountType}
                              >
                                  <option value="CHECKING_ACCOUNT">Conta corrente</option>
                                  <option value="SAVINGS_ACCOUNT">Conta poupança</option>
                              </SelectButtonV2>
                          </Flex>

                          <Flex 
                              w="100%" 
                              gap={4}
                          >
                              <Input
                                  label="Agência"
                                  type="text"
                                  name="agency"
                                  placeholder="0000"
                                  register={bankingForm.register("agency")}
                                  errors={bankingForm.formState.errors.agency}
                                  noMarginOnError
                              />
                              <Input
                                  label="Dígito"
                                  type="text"
                                  name="agencyDigit"
                                  placeholder="0"
                                  register={bankingForm.register("agencyDigit")}
                                  errors={bankingForm.formState.errors.agencyDigit}
                                  maxLength={1}
                                  noMarginOnError
                                  tooltipText="Se o dígito for X ou não existir, digite 0."
                              />
                          </Flex>

                          <Flex 
                              w="100%" 
                              gap={4}
                          >
                              <Input
                                  label="Conta"
                                  type="text"
                                  name="account"
                                  placeholder="00000000"
                                  register={bankingForm.register("account")}
                                  errors={bankingForm.formState.errors.account}
                                  noMarginOnError
                              />
                              <Input
                                  label="Dígito"
                                  type="text"
                                  name="accountDigit"
                                  placeholder="0"
                                  register={bankingForm.register("accountDigit")}
                                  errors={bankingForm.formState.errors.accountDigit}
                                  maxLength={1}
                                  noMarginOnError
                                  tooltipText="Se o dígito for X ou não existir, digite 0."
                              />
                          </Flex>

                          <Flex 
                              w="100%"
                              gap={4}
                            >
                              <SelectButtonV2
                                  name="pixKeyType"
                                  label="Tipo de chave pix"
                                  variant="ghost"
                                  bgColor="#EDF1F7"
                                  register={{ ...bankingForm.register("pixKeyType") }}
                                  errors={bankingForm.formState.errors.pixKeyType}
                              >
                                  <option value="ALLEATORY_KEY">Chave Aleatoria</option>
                                  <option value="DOCUMENT">Cnpj</option>
                                  <option value="EMAIL">E-mail</option>
                                  <option value="PHONE">Telefone</option>
                              </SelectButtonV2>
     
                              <Input
                                label="Chave Pix"
                                type="text"
                                name="pixKey"
                                placeholder={formatPixKeyPlaceholders(pixKeyType)}
                                register={bankingForm.register("pixKey")}
                                mask={formatPixKeyMasks(pixKeyType)}
                                errors={bankingForm.formState.errors.pixKey}
                                visibility={pixKeyType === "ALLEATORY_KEY" ? "hidden" : "visible"}
                                maxLength={pixKeyType === "DOCUMENT" ? 18 : 99}
                              />
                          </Flex>
                          <Flex w="100%" justify="space-between">
                          <Button variant="outline" onClick={() => setCurrentStep(2)}>
                              Voltar
                          </Button>
                          <Button 
                              type="submit" 
                              colorScheme="pink"
                              isLoading={isLoading}
                              isDisabled={isLoading}
                          >
                              Cadastrar vendedor
                          </Button>
                          </Flex>
                      </VStack>
                      </form>
                  )}
              </Flex>
            </Stack>
          </Flex>
      </Flex>

      <BannerLateral />
    </Flex>
  );
};

export default Register;