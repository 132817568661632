import { Button, Flex, Img, Input, Switch, Text, Image, Checkbox, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./customFields.scss"

interface fields {
    key?: string;
    value?: string;
}
interface CustomFields {
    label?: string;
    name: string;
    tooltipText?: string;
    hasSwitch?: boolean;
    register?: any;
    setMetadadosDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
    setMetadados?: React.Dispatch<React.SetStateAction<Array<fields>>>;
    defaultMetadados?: fields[];
}

export function CustomFields({
    label,
    tooltipText,
    name,
    hasSwitch,
    register,
    setMetadados,
    defaultMetadados,
    setMetadadosDisabled,
    ...rest
}: CustomFields) {


    const [listFields, setListFields] = useState<fields[]>([{ key: "", value: "" }])
    const [disabledState, setDisabledState] = useState<boolean>(false)
    function addFields() {
        setListFields([...listFields, { key: "", value: "" }])
    }

    function removeField(index: any) {
        const fields = [...listFields]
        fields.splice(index, 1)
        setListFields(fields)
    }

    function handleChangesFields(key: string, value: string, index: number) {
        const newListFields = [...listFields];
        newListFields[index][key] = value;
        setListFields(newListFields);
    }

    useEffect(() => {
        if (listFields[0]?.key !== "" || listFields[0]?.value !== "") {
            setMetadados(listFields)
        } else {
            setMetadados([])
        }
    }, [listFields])

    useEffect(() => {
        setMetadadosDisabled(disabledState);
    }, [disabledState])


    useEffect(() => {
        if (defaultMetadados?.length > 0) {
            setListFields(defaultMetadados)
            setDisabledState(true)
        }

    }, [defaultMetadados])

    return (
        <Flex flexDir="column" gap="4px"
            m="12px 0"
            p={disabledState ? "16px" : "0 16px"}
            backgroundColor={disabledState ? "rgba(247, 248, 251, 1)" : ""}

        >

            {hasSwitch && (
                <Flex
                    w="100%"
                    gap="12px"
                    mb="12px"
                >
                    <Checkbox
                        isChecked={disabledState}
                        defaultChecked={true}
                        onChange={() => setDisabledState(!disabledState)}
                        size="md"
                    />
                    <Text
                        fontWeight={disabledState ? "600" : "400"}
                        color={disabledState ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 1)"}

                        fontFamily="Inter"
                        fontSize="16px"
                    >
                        {label}
                    </Text>

                </Flex>
            )}


            {
                disabledState && (
                    <>
                        {
                            listFields?.map((item, index) => {
                                return (
                                    <Flex w={index === 0 ? "91%" : "100%"} align="center" justifyContent="center" marginBottom="14px" alignItems="end"
                                        key={index}

                                    >
                                        <Box w="100%">

                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                fontFamily="Inter"
                                                color="rgba(85, 96, 119, 1)"
                                                mb="5px"
                                            >
                                                Nome
                                            </Text>
                                            <Input
                                                fontFamily="'Inter', sans-serif"
                                                placeholder="Nome"
                                                height="32px"
                                                borderRadius="2px 0 0 2px"
                                                backgroundColor="rgba(255, 255, 255, 1)"
                                                fontWeight="400"
                                                fontSize="14px"
                                                borderRight="none"
                                                value={item.key}
                                                onChange={(e) => handleChangesFields("key", e.target.value, index)}
                                            />
                                        </Box>
                                        <Box w="100%">

                                            <Text
                                                fontSize="12px"
                                                fontWeight="400"
                                                fontFamily="Inter"
                                                color="rgba(85, 96, 119, 1)"
                                                mb="5px"
                                            >Valor</Text>
                                            <Input
                                                fontFamily="'Inter', sans-serif"
                                                placeholder="Valor"
                                                height="32px"
                                                borderRadius="0 2px 2px 0"
                                                backgroundColor="rgba(255, 255, 255, 1)"
                                                fontWeight="400"
                                                fontSize="14px"
                                                value={item.value}
                                                onChange={(e) => handleChangesFields("value", e.target.value, index)}
                                            />
                                        </Box>
                                        {
                                            index >= 1 && (
                                                <Image
                                                    w="24px"
                                                    h="24px"
                                                    border="1px solid #E6E9F0"
                                                    borderRadius="2px"
                                                    marginLeft="10px"
                                                    id="excluir_cobranca"
                                                    onClick={() => removeField(index)}
                                                    cursor="pointer"
                                                    fill="blackAlpha.900"
                                                    src="/images/icons/trash-delete.svg"
                                                    _hover={{
                                                        transition: "filter .2s",
                                                        filter: "brightness(.3)",
                                                    }}
                                                    mb="5px"
                                                />

                                            )
                                        }
                                    </Flex>
                                )
                            })
                        }
                        <Flex
                            marginBottom="21px"
                        >
                            <Button
                                w="16px"
                                backgroundColor="white"
                                fontSize="20px"
                                color="black"
                                fontWeight="200"
                                border="1px solid rgba(0, 0, 0, 0.15)"
                                onClick={() => addFields()}
                            >
                                +
                            </Button>
                        </Flex>

                        <Flex >
                            <Text
                                fontFamily="'Inter', sans-serif"
                                color="#8A94A8"
                                fontSize="12px"
                                lineHeight="17.54px"
                                fontWeight="400"
                            >
                                <b> Esses dados não são exibidos no checkout. </b>
                                Os dados customizados servem apenas para gerar informações adicionais e possibilitar maior controle das cobranças geradas a partir desse link de pagamento.
                            </Text>
                        </Flex>
                    </>
                )
            }

        </ Flex>
    )
}

