import { InfoCircleOutlined, InfoIcon } from "@/styles/icons"
import { InfoIconNotFilled } from "@/styles/icons/InfoIconNotFilled"
import { InfoIconOutlineBlue } from "@/styles/icons/InfoIconOutlineBlue"
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Text, Flex } from "@chakra-ui/react"

export function HelperToLoggin() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Button
                backgroundColor="white"
                border="none"
                _hover={{ opacity: "0.7" }}
                onClick={onOpen}
            >
                <Text
                    color="#1890FF"
                    fontSize="14px"
                    fontWeight="400"
                >

                    Está com dificuldades para acessar?
                </Text>

            </Button>


            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent p="0" w="400px" h="255px" borderRadius="2px">
                    <Flex p="32px" gap="16px">
                        <InfoIconOutlineBlue size={24} />
                        <Flex flexDirection="column" w="90%">

                            <ModalHeader 
                            p="0" 
                            fontSize="16px"
                            color="rgba(0, 0, 0, 0.85)"
                            >Está com dificuldades para acessar?</ModalHeader>
                            <ModalBody
                                p="5px 0" 
                                fontSize="14px"
                                color="rgba(0, 0, 0, 0.85)"
                            >
                                Caso você esteja com dificuldades para acessar ou tenha perdido acesso ao seu aplicativo de autenticação, por favor entre em contato com o nosso suporte por email ou telefone.
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    onClick={onClose}
                                    // isLoading={loading}
                                    type="submit"
                                    h="32px"
                                    borderRadius="2px"
                                    variant="solid"
                                    w="49px"
                                    color="white"
                                    backgroundColor="rgba(24, 144, 255, 1)"
                                    _hover={{ opacity: "0.7" }}
                                >
                                    Ok
                                </Button>
                            </ModalFooter>
                        </Flex>
                    </Flex>
                </ModalContent>
            </Modal>
        </>
    )
}