import { IconProps } from "@/models";


export function CreditCardIcon({
    size = 20,
    color = "#000",
  }: IconProps) {
    return (
        <svg 
            width={size}
            height={size}
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1.75" y="4.75" width="20.5" height="14.5" rx="1.25" stroke={color} stroke-width="1.5"/>
            <line x1="1.75" y1="9.25" x2="21.25" y2="9.25" stroke={color} stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    );
  }
  

