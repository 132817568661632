import { createContext, ReactNode, useContext, useState } from "react";
import { Pageable } from "@/models";
import { showToast, filterAndPageableToRequest } from "@/utils";
import { Api_Payment } from "@/services/Api/paymentApiConfig";
import { Api_Banking } from "@/services/Api/bankingApiConfig";

interface CreditCardProps {
  children: ReactNode;
}

interface CreditCardContextData {
  creditCards: any;
  createCreditCard: (data: any) => void;
  creditCardData: any;
  getCreditCards: (filter: Pageable | {}, pageable: Pageable | {}) => any;
  getInitialCreditCardData: (cardId: string) => any;
  getInitialData: () => any;
  loading: boolean;
  setLoading: (value: boolean) => void;
  creditCardLoading: boolean;
  setCreditCardLoading: (value: boolean) => void;
  selectedCreditCardName: string;
  setSelectedCreditCardName: (value: string) => void;
  creditCardTransactionsData: Array<any>;
  initialDataIsLoading: boolean;
  creditCardLimit: any;
  updateCardStatus: (status: string, cardId: string) => void;
  getTotalValueTransactions: (array: Array<any>) => number;
  setCreditCardData: (value: any) => void;
}

const CreditCardContext = createContext<CreditCardContextData>(
  {} as CreditCardContextData
);

const CreditCardProvider = ({ children }: CreditCardProps) => {
  const [initialDataIsLoading, setInitialDataIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creditCardLoading, setCreditCardLoading] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [creditCardData, setCreditCardData] = useState({} as any);
  const [creditCardTransactionsData, setCreditCardTransactionData] = useState<any>([]);
  const [creditCardLimit, setCreditCardLimit] = useState({} as any);
  const [selectedCreditCardName, setSelectedCreditCardName] = useState("");
  const baseUrl = "cards";
  const creditCardBaseUrl = "card"
  const transactionsBaseUrl = "transactions"
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  
  const createCreditCard = async (data) => {
    setLoading(true);
    try {
      await Api_Banking.post(baseUrl, data);
      showToast({
        type: "success",
        message: "Cartao de credito criado com sucesso.",
      });
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setLoading(false);
    }
  };



  const getCreditCards = (
    filter?: Pageable | {},
    pageable?: Pageable | {}
  ) => {
    return Api_Banking.get<any>(
      `${baseUrl}${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const getCreditCardId = (
    cardId: string,
  ) => {
    return Api_Payment.get<any>(
      `${creditCardBaseUrl}/${cardId}`
    );
  };

  const getCreditCardTransactions = (cardId: string) => {
    return Api_Banking.get<any[]>(
      `${transactionsBaseUrl}?cardId=${cardId}`
    )
  }

  const getCreditCardLimit = () => {
    return Api_Banking.get<any[]>(
      `${baseUrl}/limit`
    )
  }

  const updateCardStatus = (status: "ACTIVE" | "BLOCKED", cardId: string) => {
    return Api_Banking.patch(`${baseUrl}`, {
      status,
      idCard: cardId,
      idSeller: user?.idSeller,
    });
  }
  const getTotalValueTransactions = (array) => {
    return array.reduce((soma, item) => {
        if (item.approved === true) {
            return soma + item.billingAmount;
        }
        return soma;
    }, 0);
  }

  async function getInitialData() {
    try {
      setInitialDataIsLoading(true);
      const response = await getCreditCards({}, {});
      setCreditCards(response?.data);
      const response_limit = await getCreditCardLimit();
      setCreditCardLimit(response_limit?.data);
    } catch(error)  {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setInitialDataIsLoading(false);
    }
  }

  async function getInitialCreditCardData(cardId) {
    try {
      setCreditCardLoading(true);
      const response = await getCreditCardId(cardId);
      setCreditCardData(response?.data);
      const response_transactions = await getCreditCardTransactions(cardId);
      setCreditCardTransactionData(response_transactions?.data?.reverse());
      // const response_limit = await getCreditCardLimit();
      // setCreditCardLimit(response_limit?.data);
    } catch(error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    } finally {
      setCreditCardLoading(false);
    }
  }



  return (
    <CreditCardContext.Provider
      value={{
        createCreditCard,
        creditCards,
        loading,
        setLoading,
        getCreditCards,
        getInitialData,
        getInitialCreditCardData,
        setSelectedCreditCardName,
        selectedCreditCardName,
        creditCardData,
        creditCardLoading,
        setCreditCardLoading,
        creditCardTransactionsData,
        initialDataIsLoading, 
        creditCardLimit,
        updateCardStatus,
        getTotalValueTransactions,
        setCreditCardData
      }}
    >
      {children}
    </CreditCardContext.Provider>
  );
};

export function useCreditCard(): CreditCardContextData {
  return useContext(CreditCardContext);
}

export { CreditCardProvider };
