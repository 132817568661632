export function LogoBarteCreditCard() {
  return (
    <svg width="76" height="24" viewBox="0 0 76 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4857_2697)">
            <path 
                d="M33.1882 9.43722C32.7825 8.88902 32.2721 8.46346 31.6553 8.16055C31.0384 7.85578 30.3096 7.7034 29.4688 7.7034C28.628 7.7034 27.9469 7.86693 27.3393 8.194C26.7316 8.52107 26.1937 8.9968 25.7274 9.61934V3.64294L23.1812 3.64294L23.1812 19.9312H25.6595V18.517C26.1552 19.1563 26.7041 19.6116 27.3044 19.8866C27.9047 20.1598 28.5583 20.2973 29.265 20.2973C30.0453 20.2973 30.7521 20.1486 31.3836 19.8532C32.0151 19.5558 32.5548 19.134 33.0064 18.5877C33.4562 18.0394 33.8032 17.3742 34.0437 16.5918C34.2842 15.8094 34.4035 14.9305 34.4035 13.9567C34.4035 12.9829 34.3025 12.182 34.0987 11.4126C33.895 10.6451 33.5921 9.98729 33.1863 9.43908L33.1882 9.43722ZM31.4974 15.9395C31.3469 16.4729 31.1376 16.9021 30.8659 17.2292C30.596 17.5563 30.2802 17.796 29.9186 17.9484C29.5588 18.1008 29.1677 18.177 28.7473 18.177C28.3563 18.177 27.9818 18.1231 27.6202 18.0171C27.2603 17.9112 26.9409 17.7124 26.6619 17.4243C26.3846 17.1363 26.1588 16.7479 25.9863 16.261C25.8137 15.7741 25.7201 15.1516 25.7054 14.3897V13.6371C25.7054 13.074 25.766 12.5611 25.8853 12.0965C26.0046 11.6319 26.1937 11.2342 26.4489 10.8997C26.7041 10.5652 27.0309 10.3069 27.4292 10.1248C27.8276 9.94269 28.3049 9.85163 28.8612 9.85163C29.2375 9.85163 29.601 9.92782 29.9535 10.0802C30.306 10.2326 30.6107 10.4723 30.8659 10.7994C31.1211 11.1264 31.3285 11.552 31.4864 12.0761C31.6443 12.6001 31.7232 13.2357 31.7232 13.9808C31.7232 14.726 31.6479 15.4099 31.4974 15.9432V15.9395Z" 
                fill="white"
            />
            <path 
                d="M45.0108 9.07208C44.6509 8.64652 44.1663 8.31202 43.5568 8.06858C42.9491 7.82514 42.1781 7.70435 41.2473 7.70435C39.8044 7.70435 38.6699 7.97009 37.8437 8.50343C37.0176 9.03677 36.4981 9.88045 36.2888 11.0363L38.6331 11.2872C38.7525 10.7706 39.0058 10.3748 39.3877 10.1016C39.7713 9.82842 40.3606 9.6909 41.1574 9.6909C41.8477 9.6909 42.4076 9.86187 42.8372 10.2038C43.2649 10.5457 43.4797 11.1664 43.4797 12.064V12.5657L41.7448 12.6568C41.0234 12.6884 40.3019 12.759 39.5804 12.8742C38.8589 12.9876 38.2091 13.1901 37.6308 13.4782C37.0525 13.7681 36.5825 14.1658 36.2227 14.675C35.8629 15.1841 35.6812 15.8494 35.6812 16.6708C35.6812 17.2785 35.7821 17.8081 35.9859 18.256C36.1878 18.7038 36.4669 19.0736 36.8194 19.3617C37.1718 19.6516 37.5886 19.8671 38.0695 20.0121C38.5505 20.157 39.0682 20.2295 39.6245 20.2295C40.5259 20.2295 41.2914 20.0548 41.9229 19.7055C42.5544 19.3561 43.0868 18.9157 43.5238 18.3823V19.934H45.9801V12.5193C45.9801 11.8206 45.9085 11.1776 45.7653 10.5922C45.6221 10.0068 45.3706 9.50135 45.0108 9.0758V9.07208ZM43.4779 15.0039C43.4779 15.9925 43.208 16.7749 42.6664 17.3528C42.1249 17.9307 41.2914 18.2188 40.1642 18.2188C39.5327 18.2188 39.0554 18.0738 38.7323 17.7858C38.4092 17.4978 38.2476 17.0945 38.2476 16.576C38.2476 16.288 38.288 16.0167 38.3706 15.7658C38.4532 15.5149 38.6221 15.2938 38.8773 15.1042C39.1325 14.9147 39.4978 14.7586 39.9696 14.6359C40.4433 14.5151 41.0619 14.4315 41.8293 14.3851L43.4742 14.3163V15.0002L43.4779 15.0039Z" 
                fill="white"
            />
            <path 
                d="M53.7664 7.88742C53.1202 7.88742 52.5107 8.05467 51.9416 8.38917C51.3707 8.72367 50.8824 9.20312 50.4766 9.82566V8.06953H47.9983V19.9331H50.5446V12.9755C50.5446 12.641 50.6088 12.3102 50.7355 11.9832C50.8622 11.6561 51.0513 11.3644 51.2991 11.1042C51.5469 10.8459 51.8517 10.6359 52.2115 10.4761C52.5713 10.3163 52.9917 10.2363 53.4727 10.2363C53.6838 10.2363 53.8858 10.2438 54.0803 10.2587C54.2749 10.2735 54.4567 10.3051 54.6219 10.3497L54.9156 7.97662C54.7504 7.94688 54.5815 7.92273 54.409 7.90786C54.2364 7.89299 54.0216 7.88556 53.7664 7.88556V7.88742Z" 
                fill="white"
            />
            <path 
                d="M62.7101 18.0147C62.4769 18.0445 62.2328 18.0612 61.9776 18.0612C61.4966 18.0612 61.1331 17.9237 60.8853 17.6505C60.6375 17.3773 60.5126 16.935 60.5126 16.3274V10.1224H63.4205V8.06892H60.5126V4.87445L57.9663 4.87445V8.06892H56.1177V10.1224H57.9663V16.0988C57.9663 17.4516 58.2215 18.4682 58.7319 19.1446C59.2422 19.821 60.1436 20.1592 61.436 20.1592C62.2768 20.1592 63.0589 20.0366 63.7804 19.795L63.3967 17.8568C63.1709 17.933 62.9414 17.9868 62.7101 18.0166V18.0147Z" 
                fill="white"
            />
            <path 
                d="M75.598 13.4972C75.598 12.6312 75.4934 11.8432 75.2823 11.1352C75.0712 10.4272 74.7481 9.81952 74.313 9.31034C73.8779 8.80115 73.329 8.40533 72.6681 8.12472C72.0072 7.84411 71.2251 7.70288 70.3237 7.70288C69.4829 7.70288 68.7119 7.84411 68.0143 8.12472C67.3148 8.40719 66.7145 8.81602 66.2115 9.3568C65.7085 9.89757 65.3175 10.5629 65.0403 11.3526C64.7631 12.1443 64.6235 13.0493 64.6235 14.0677C64.6235 16.0449 65.1082 17.5781 66.0775 18.6652C67.0468 19.7523 68.4622 20.2968 70.3256 20.2968C71.8732 20.2968 73.0591 19.9586 73.8871 19.2822C74.7132 18.6057 75.2309 17.6505 75.442 16.4185H72.9636C72.8278 17.0113 72.5579 17.4684 72.1522 17.7881C71.7465 18.1077 71.1388 18.2675 70.3274 18.2675C69.2902 18.2675 68.5173 17.933 68.0069 17.264C67.4966 16.595 67.2249 15.7197 67.1955 14.64L75.6017 14.64V13.499L75.598 13.4972ZM67.2377 12.8375C67.313 11.8786 67.5976 11.1148 68.0951 10.5443C68.5907 9.97376 69.3122 9.68944 70.2595 9.68944C71.2068 9.68944 71.9723 9.9719 72.4239 10.5331C72.8737 11.0962 73.0995 11.8637 73.0995 12.8375H67.2396H67.2377Z" 
                fill="white"
            />
            <path 
                d="M14.5057 11.5782H11.0488L15.1813 2.13044C15.3795 1.67887 15.3373 1.18083 15.0693 0.76271C14.7627 0.285118 14.2175 0.000793457 13.6135 0.000793457L6.5364 0.000793457C5.41839 0.000793457 4.42889 0.610326 4.01766 1.5525L0.107378 10.4874C-0.0670246 10.885 -0.0303083 11.3236 0.206512 11.6916C0.474541 12.1097 0.950017 12.3587 1.4769 12.3587H4.83277L0.608555 22.0202C0.430481 22.4271 0.467198 22.8769 0.709525 23.2541C0.984898 23.6815 1.47139 23.938 2.01295 23.938H9.23872C10.4559 23.938 11.5317 23.2764 11.9796 22.2506L15.8458 13.4087C16.0166 13.0184 15.9817 12.5891 15.7485 12.2286C15.486 11.8198 15.0215 11.5763 14.5075 11.5763L14.5057 11.5782ZM1.47506 11.5782C1.2107 11.5782 0.977554 11.4611 0.852719 11.266C0.79948 11.1824 0.722376 11.0114 0.812331 10.8033L4.72262 1.86656C5.00717 1.21614 5.73599 0.779435 6.5364 0.779435L11.5922 0.779435C11.8566 0.779435 12.0897 0.89651 12.2146 1.09163C12.2678 1.17526 12.3449 1.34623 12.255 1.55436L8.37038 10.4372C8.07114 11.1192 7.30745 11.5782 6.46664 11.5782H1.4769H1.47506ZM15.139 13.0946L11.2728 21.9365C10.9534 22.6669 10.1346 23.1593 9.23689 23.1593H4.29854C4.0452 23.1593 3.82307 23.0478 3.70374 22.862C3.65234 22.7821 3.58074 22.6204 3.66519 22.4234L7.715 13.1634C7.92428 12.6839 8.46584 12.3606 9.06248 12.3606H14.5075C14.7609 12.3606 14.983 12.4721 15.1023 12.6579C15.1537 12.7378 15.2253 12.8995 15.1409 13.0946H15.139Z" 
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4857_2697">
            <rect width="75.6" height="23.94" fill="white"/>
            </clipPath>
        </defs>
    </svg>
  );
}



