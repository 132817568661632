import { useNavigate } from "react-router-dom";
import {  Text, Stack } from "@chakra-ui/react";
import { WithAuth } from "@/hoc";
import { FormModal2, Input } from "@/components";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreditCard } from "@/hooks/useCreditCard";

interface NewCreditCardProps {
    isOpen: boolean;
    onClose: ()=> void;
}
export default function NewCreditCard({isOpen, onClose}: NewCreditCardProps) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    cardName: yup.string().required('Este campo é obrigatório'),
  });
  const {
    register,
      handleSubmit,
      formState: { errors },
  } = useForm({
      resolver: yupResolver(schema)
  });
  const { createCreditCard, loading, getInitialData } = useCreditCard();
  async function onSubmit(data) {
    try {
        await createCreditCard(data);
        navigate("/gerenciar-cartoes");
        
    } finally {
        getInitialData();
        onClose();
    }
  }

  return (
    <>
      <FormModal2
        isOpen={isOpen}
        onClose={onClose}
        title="Novo cartão de crédito"
        handleConfirmModal={handleSubmit(onSubmit)}
        isSubmitting={loading}
        confirmationButtonText="Gerar cartão"
        disableWhenIsFormEmpty={false}
        type="ADD"
      >
        <Stack
          spacing={8}
        >
          <Text
            fontSize={["14px"]}
          >
            Inclua um nome para identificar o cartão:
          </Text>
          <Input
            label="Nome no cartão"
            labelColor="#8A94A8"
            placeholder="Identifique o cartão"
            name="cardName"
            type="text"
            register={{ ...register("cardName") }}
            borderRadius="8px"
            errors={errors.cardName}
          />
        </Stack>
      </FormModal2>
    </>
  );
};
