
export function MastercardLogoCreditCard() {
    return (
        <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
            <g filter="url(#filter0_b_4848_1907)">
            <ellipse cx="8.62072" cy="9.95236" rx="8.62072" ry="9.28891" fill="url(#paint0_linear_4848_1907)" fill-opacity="0.6"/>
            </g>
            <g filter="url(#filter1_b_4848_1907)">
            <path d="M27.9299 9.95224C27.9299 15.0824 24.0703 19.2411 19.3092 19.2411C14.5481 19.2411 10.6885 15.0824 10.6885 9.95224C10.6885 4.82212 14.5481 0.66333 19.3092 0.66333C24.0703 0.66333 27.9299 4.82212 27.9299 9.95224Z" fill="url(#paint1_linear_4848_1907)" fill-opacity="0.8"/>
            </g>
            <g filter="url(#filter2_b_4848_1907)">
            <path d="M13.9644 2.66382C11.9688 4.36512 10.6885 6.99764 10.6885 9.95239C10.6885 12.9071 11.9688 15.5397 13.9644 17.241C15.9599 15.5397 17.2402 12.9071 17.2402 9.95239C17.2402 6.99764 15.9599 4.36512 13.9644 2.66382Z" fill="url(#paint2_linear_4848_1907)" fill-opacity="0.6"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_b_4848_1907" x="-11.5102" y="-10.8468" width="40.2619" height="41.5982" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.75512"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4848_1907"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4848_1907" result="shape"/>
            </filter>
            <filter id="filter1_b_4848_1907" x="-0.821758" y="-10.8469" width="40.2619" height="41.5982" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.75512"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4848_1907"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4848_1907" result="shape"/>
            </filter>
            <filter id="filter2_b_4848_1907" x="-0.821758" y="-8.84642" width="29.5722" height="37.5976" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.75512"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4848_1907"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4848_1907" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_4848_1907" x1="8.62072" y1="0.663452" x2="8.62072" y2="19.2413" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0.71"/>
            <stop offset="1" stop-color="white"/>
            </linearGradient>
            <linearGradient id="paint1_linear_4848_1907" x1="19.3092" y1="0.66333" x2="19.3092" y2="19.2411" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0.45"/>
            <stop offset="1" stop-color="white"/>
            </linearGradient>
            <linearGradient id="paint2_linear_4848_1907" x1="13.9644" y1="2.66382" x2="13.9644" y2="17.241" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0.07"/>
            <stop offset="1" stop-color="white"/>
            </linearGradient>
            </defs>
        </svg>
    );
  }
  
  
  
  