import { Box, Flex, Switch, Text, Checkbox } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Input, Select, DatePickerInput } from "@/components";
import { RadioGroupBody } from "../RadioGroupBody";
import { MethodProps } from "@/models";
import { useAdvanceReceipt } from "../useAdvanceReceipt";
import { installments as installmentsSelect , installments21x } from "@/utils";
import { usePreviewContext } from "@/contexts/PreviewContext";
import { Tooltip } from "@/components/Tooltip";


interface PaymentMethod {
  discount: any
}

interface CustomInstallmentValue {
  paymentMethod: "PIX" | "BANK_SLIP" | "CREDIT_CARD" | "CREDIT_CARD_EARLY_BUYER" | "CREDIT_CARD_EARLY_SELLER";
  installments: number;
}

export type ListOfCustomInstallmentValue = Array<CustomInstallmentValue>;

export function Method({
  setValue,
  creditCardType,
  register,
  control,
  initialData,
  title,
  id,
  reg,
  watch,
  anchoringState,
  paymentLinkType,
  setCreditCardType,
  setAnchoringState,
  dateSwitchState,
  setDateSwitchState,
  isPunctualCharge,
  setPaymentsMethodsActive,
  split,
  hasInstallmentMix
}: MethodProps) {
  const methodInitialState: PaymentMethod = watch(reg);
  const { previewData, setPreviewData } = usePreviewContext();
  const name = `${reg}Percent`;
  const [methodState, setMethodState] = useState(!!methodInitialState);
  const [creditCardReceivement, setCreditCardReceivement] = useState<
    "RECURRENT" | "EARLY"
  >(creditCardType === "RECURRENT" ? "RECURRENT" : "EARLY");

  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const [creditCardTax, setCreditCardTax] = useState<"EARLY_BUYER" | "EARLY_SELLER">(
    creditCardType === "EARLY_BUYER" || creditCardType === "EARLY_SELLER" ? creditCardType : "EARLY_BUYER");

  const verifyCreditCardCustomInstallment = () => watch("customInstallmentsValuesCreditCard") ? "customInstallmentsValuesCreditCard" : "installments";

  const { installmentArray } = useAdvanceReceipt(anchoringState, watch, `enableCreditCardPercent`, paymentLinkType, verifyCreditCardCustomInstallment());
  const featureTogglesUser = localStorage?.getItem("barte-features-list-user");


  function handleMethodState() {

    setValue(reg, !methodState)
    setMethodState((prevMethodState) => !prevMethodState);
    setPreviewData((prevPreviewData) => ({
      ...prevPreviewData,
      [reg]: !methodState,
    }));
  }

  useEffect(() => {
    if (creditCardReceivement === "EARLY") {
      setCreditCardType(creditCardTax);
    } else {
      setCreditCardType(creditCardReceivement);
    }

  }, [creditCardTax, creditCardReceivement]);

  useEffect(() => {
    setMethodState(!!methodInitialState);
  }, [watch(reg)]);
  function formatIdName(id: string) {
    let idNames = {
      "creditCard": "Cartão",
      "creditCardWithoutAntecipation": "Cartão",
      "bankSlip": "Boleto",
      "pix": "Pix"
    }
    return idNames[id];
  }
  function formatRegisterIdName(id: string): string {
    let idNames = {
      "creditCard": "customInstallmentsValuesCreditCard",
      "creditCardWithoutAntecipation": "customInstallmentsValuesCreditCard",
      "bankSlip": "customInstallmentsValuesBankSlip",
      "pix": "customInstallmentsValuesPix"
    }
    return idNames[id];
  }
  useEffect(() => {
    setPaymentsMethodsActive((prevState) => ({
      ...prevState,
      [reg]: methodInitialState
    }))

  }, [methodInitialState])

  return (
    <Flex p="1.5rem 0" flexDir="column" gap="1.5rem">
      <Flex align="center" justify="space-between">
        <Flex
          align="center"
          justify="space-between"
          gap="11px"
        >
          <Checkbox
            name={reg}
            {...register(reg)}
            isChecked={methodState}
            defaultChecked={methodState}
            onChange={handleMethodState}
            disabled={(id === "bankSlip" && split) || (id === "pix" && split)}
          />

          <Text fontSize="1rem" color="black" fontWeight="600">
            {title}
          </Text>
          {id === "creditCardWithoutAntecipation" && (
            <Box
              bgColor="rgba(27, 214, 168, 0.3)"
              color="#20473D"
              fontSize="12px"
              lineHeight="14.52px"
              p="4px 8px"
              borderRadius="4px"
            >
              35% menos inadimplência
            </Box>
          )}
          {(id === "bankSlip" && split) && (
            <Tooltip label="Método de boleto bloqueado por causa que o split está ativado.">

            </Tooltip>
          )}
          {(id === "pix" && split) && (
            <Tooltip label="Método de pix bloqueado por causa que o split está ativado.">

            </Tooltip>
          )}
        </Flex>
      </Flex>
      {methodState && (
        <Flex flexDir="column" gap="1.5rem">

          {methodInitialState?.discount && (
            <Input
              maxLength={3}
              mask="percentage"
              type="text"
              name={name}
              setValue={setValue}
              switchActive={methodInitialState?.discount ?? false}
              hasSwitch
              placeholder="0%"
              label={`Desconto no ${title}`}
              register={{ ...register(name) }}
            />
          )}
          {!methodInitialState?.discount && (
            <Input
              maxLength={3}
              mask="percentage"
              type="text"
              name={name}
              setValue={setValue}
              switchActive={methodInitialState?.discount}
              hasSwitch
              placeholder="0%"
              label={`Desconto no ${title}`}
              register={{ ...register(name) }}
            />

          )}

          {(isPunctualCharge && id === "creditCard") && (
            <>
                <DatePickerInput
                  tooltipText="Data de vencimento da primeira cobrança.
                  A data dos próximos vencimentos, se houver,
                  será no mesmo dia do mês."
                  startEnabled={initialData?.scheduledDate}
                  disabledState={dateSwitchState}
                  setDisabledState={setDateSwitchState}
                  label="Agendar primeira cobrança no cartão para:"
                  hasSwitch
                  isCustomizableDate
                  selectedDate={new Date()}
                  control={control}
                  name="date"
                  register={{ ...register("date") }}
                  rules={{ required: "Preenchimento obrigatório!" }}
                />
                <Select
                  hasInstallmentMix={hasInstallmentMix}
                  label={hasInstallmentMix ? "Assumir as taxas de parcelamento" : `Parcelamento Máximo no ${formatIdName(id)} `}
                  name={`${formatRegisterIdName(id)}`}
                  defaultValue={initialData ? initialData?.paymentOrder?.customInstallmentsValues[0]?.installments : user.idSeller === 4483 ? 6 : 10}
                  optionsList={featureTogglesUser?.includes("view_seller_full_installments") ? installmentsSelect.map((item, index) => ({ id: index, title: item.title, value: item.value })) : installments21x.map((item, index) => ({ id: index, title: item.title, value: item.value }))}
                  // hasSwitch
                  setValue={setValue}
                  register={{ ...register(formatRegisterIdName(id)) }}
                />
            </>
      
          )}
          {(isPunctualCharge && id === "bankSlip") && (
            <Select
              label={`Parcelamento Máximo no ${formatIdName(id)} `}
              name={`${formatRegisterIdName(id)}`}
              defaultValue={initialData?.paymentOrder?.customInstallmentsValues[1]?.installments}
              optionsList={Array.from({ length: 21 }, (_, i) => ({ id: `${i + 1}`, title: `${i + 1}x`, value: i + 1 }))}
              // hasSwitch
              setValue={setValue}
              register={{ ...register(formatRegisterIdName(id)) }}
            />
          )}
          {(isPunctualCharge && id === "pix") && (
            <Select
              label={`Parcelamento Máximo no ${formatIdName(id)} `}
              name={`${formatRegisterIdName(id)}`}
              defaultValue={initialData?.paymentOrder?.customInstallmentsValues[2]?.installments}
              optionsList={Array.from({ length: 12 }, (_, i) => ({ id: `${i + 1}`, title: `${i + 1}x`, value: i + 1 }))}
              // hasSwitch
              setValue={setValue}
              register={{ ...register(formatRegisterIdName(id)) }}
            />
          )}

          {id === "creditCard" && !hasInstallmentMix &&(
            <RadioGroupBody
              array={installmentArray}
              creditCardReceivement={creditCardReceivement}
              setCreditCardReceivement={setCreditCardReceivement}
              creditCardTax={creditCardTax}
              setCreditCardTax={setCreditCardTax}
              isPunctualCharge={isPunctualCharge}
              setAnchoringState={setAnchoringState}
              anchoringState={anchoringState}
              creditCardType={creditCardType}
              selectedInstallments={watch("customInstallmentsValuesCreditCard")}
            />
          )}
        </Flex>
      )}
    </Flex>
  );
}
