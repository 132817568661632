import { 
    AlertDialog, 
    AlertDialogBody, 
    AlertDialogContent, 
    AlertDialogFooter, 
    AlertDialogHeader, 
    AlertDialogOverlay, 
    Button, 
    Divider, 
    Flex, 
    Text
} from "@chakra-ui/react";
import { useCreditCard } from "@/hooks/useCreditCard";
import { useRef } from "react";
import { LockIcon } from "@/styles/icons/LockIcon";
import { useParams } from "react-router-dom";
import { showToast } from "@/utils";

interface BlockCreditCardProps {
    isOpen: boolean;
    onClose: ()=> void;
}
export default function BlockCreditCard({isOpen, onClose}: BlockCreditCardProps) {
    const { 
        creditCardData, 
        updateCardStatus, 
        loading, 
        setCreditCardData 
    } = useCreditCard();
    const modalRef = useRef();
    const isBlocked = creditCardData?.cardStatus === "BLOCKED";
    const { id } = useParams();
    const formatCardStatus = (value: string) => {
        const status = {
          ACTIVE: "ativo",
          BLOCKED: "bloqueado"
        }
        return status[value] || value;
    }
    async function onSubmit() {
        try {
            const formatedCardStatus = isBlocked ? "ACTIVE" : "BLOCKED"
            await updateCardStatus(formatedCardStatus, id ?? creditCardData?.cardId);
            showToast({
                type: "success",
                message: `Cartao ${formatCardStatus(formatedCardStatus)} com sucesso.`,
            });
            const updatedCreditCardData = {
                ...creditCardData,
                cardStatus: formatedCardStatus
            }
            setCreditCardData(updatedCreditCardData);        
        } catch (error) {
            error?.response?.data?.errors.map((error) => {
            showToast({
                type: "error",
                message: `${error?.code} - ${error?.description}`,
            });
        });
        } finally {
            onClose();
        }
    }
    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={modalRef}
            motionPreset="slideInBottom"
            isCentered
        >
        <AlertDialogOverlay>
            <AlertDialogContent
                display="flex"
                flexDir="column"
                borderRadius=".5rem"
                w="400px"
            >
                <AlertDialogHeader
                    p="16px 24px" 
                    fontSize="1rem" 
                    fontWeight="bold"
                >
                    <Flex
                        align="center"
                        gap="4"
                    >
                        <LockIcon />
                        <Text
                            fontSize="1rem"
                            fontWeight={600}
                        >
                         {isBlocked ? "Desbloquear temporariamente o cartão" : "Bloquear temporariamente o cartão"}
                        </Text>
                    </Flex>
                </AlertDialogHeader>
                <Divider />
                <AlertDialogBody
                    margin="0"
                    px="1rem"
                    py="1rem"
                    w="100%"
                >
                    <Flex
                        w="100%"
                        flexDirection="column"
                        gap="1rem"
                    >
                        <Text
                            fontSize="14px"
                        >
                            {isBlocked ? "Você quer temporariamente desbloquear o cartão?" : "Você quer temporariamente bloquear o cartão?"}
                        </Text>
                        {!isBlocked && (
                            <Text
                                fontSize="12px"
                                color="#8A94A8"
                            >
                                Enquanto estiver bloqueado, seu cartão não aprovará nenhuma nova compra.
                            </Text>
                        )}
                    </Flex>
                </AlertDialogBody>
                <Divider />
                <AlertDialogFooter
                    h="5rem"
                    w="100%"
                    display="flex"
                    gap="4"
                >
                    <Button
                        variant="secondary"
                        size="sm"
                        borderRadius=".5rem"
                        fontWeight={400}
                        transition="filter .2s"
                        _hover={{
                            transition: "filter .2s",
                            filter: "brightness(.9)",
                        }}
                        _focus={{}}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="primary2"
                        size="sm"
                        borderRadius=".5rem"
                        fontWeight={400}
                        transition="filter .2s"
                        _hover={{
                            transition: "filter .2s",
                            filter: "brightness(.9)",
                        }}
                        _focus={{}}
                        onClick={onSubmit}
                        isLoading={loading}
                        isDisabled={loading}
                    >
                        {isBlocked ? "Desbloquear" : "Bloquear"}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
        </AlertDialog>
    );
};
