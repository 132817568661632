import { IconProps } from "@/models/Icon";

export function FirstStepIcon({
    size = 18,
    color = "#000",
}: IconProps) {
    return (

        <svg width={size }height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3988 1.89993C7.4407 1.89993 1.79883 7.54181 1.79883 14.4999C1.79883 21.4581 7.4407 27.0999 14.3988 27.0999C21.357 27.0999 26.9988 21.4581 26.9988 14.4999C26.9988 7.54181 21.357 1.89993 14.3988 1.89993ZM14.3988 24.9624C8.62195 24.9624 3.93633 20.2768 3.93633 14.4999C3.93633 8.72306 8.62195 4.03743 14.3988 4.03743C20.1757 4.03743 24.8613 8.72306 24.8613 14.4999C24.8613 20.2768 20.1757 24.9624 14.3988 24.9624Z" fill="black" />
            <path d="M3.9375 14.5C3.9375 8.72313 8.62313 4.03751 14.4 4.03751C20.1769 4.03751 24.8625 8.72313 24.8625 14.5C24.8625 20.2769 20.1769 24.9625 14.4 24.9625C8.62313 24.9625 3.9375 20.2769 3.9375 14.5Z" fill="black" />
            <path d="M16.2517 8.49997V20.7182H13.6684V10.952H13.5968L10.7988 12.7059V10.415L13.8235 8.49997H16.2517Z" fill="white" />
        </svg>

    )
}


















