import { useNavigate } from "react-router-dom";
import { Button, Flex, Text, Box, Heading, Badge, Spacer } from "@chakra-ui/react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { WithAuth } from "@/hoc";
import { useEffect, useState } from "react";
import { useCreditCard } from "@/hooks/useCreditCard";
import { formatCardStatus, formatFinalNumbers, getColorBadgeStatus } from "@/utils";
import NewCreditCard from "@/pages/cartoes/novo-cartao";
import { CreditCardInvoice } from "../fatura-cartao";

const ManageCreditCardPage = () => {
  const navigate = useNavigate();
  const [isNewCreditCardFormOpen, setIsNewCreditCardFormOpen] = useState(false);
  const featureTogglesUser = localStorage?.getItem("barte-features-list-user");
  const columns: ColumnsType<any[]> = [
    {
      key: "panMasked",
      title: "Cartão",
      dataIndex: "panMasked",
      render: (panMasked) => (
        <Flex align="center" gridGap="2">
          <Text>{`final `}</Text>
          <Text
            fontWeight={600}
          >
            {formatFinalNumbers(panMasked)}
          </Text>
        </Flex>
      ),
    },
    {
      key: "cardName",
      title: "Nome",
      dataIndex: "cardName",
      render: (cardName) => (
        <Flex align="center" gridGap="2">
          <Text>{cardName}</Text>
        </Flex>
      ),
    },
    {
      key: "",
      title: "",
      dataIndex: "",
    },
    {
      key: "",
      title: "",
      dataIndex: "",
    },
    {
      key: "cardStatus",
      title: "Status",
      dataIndex: "cardStatus",
      render: (cardStatus) => (
        <Flex align="center" gridGap="2">
          <Badge 
            variant={getColorBadgeStatus(cardStatus)}
          >
            {formatCardStatus(cardStatus)}
          </Badge>
        </Flex>
      ),
    },
  ];

  const { 
    getInitialData, 
    creditCards, 
    setSelectedCreditCardName, 
    initialDataIsLoading,
    creditCardLimit
  } = useCreditCard();
  const currentBalance = creditCardLimit.invoiceValue ?? 0;
  const availableLimit = creditCardLimit.availableValueVirtualCard ?? 0; 
  const totalLimit = creditCardLimit.totalValueBag ?? 0;
  const usedPercentage = (currentBalance / totalLimit) * 100;
  
  useEffect(() => {
    getInitialData();
  } , [])
  return (
    <>
      <NewCreditCard 
        isOpen={isNewCreditCardFormOpen}
        onClose={() => setIsNewCreditCardFormOpen(!isNewCreditCardFormOpen)}
      />
      <Flex 
          flexDir="column"
          width="100%"
          gap="1rem"
      >
        <Flex 
          align="center"
          justify="space-between"
        >
          <Heading
              fontSize="1.5rem"
          >
              Gerenciar cartões
          </Heading>
          <Button
              variant="primary2"
              size="sm"
              borderRadius=".5rem"
              fontWeight={400}
              transition="filter .2s"
              _hover={{
              transition: "filter .2s",
              filter: "brightness(.9)",
              }}
              _focus={{}}
              onClick={() => {
                setIsNewCreditCardFormOpen(!isNewCreditCardFormOpen);
              }}
              isDisabled={featureTogglesUser?.includes("block_items_credit_card")}
          >
            Novo cartão
          </Button>
        </Flex>
        <Flex
          w="100%"
        >
          <Spacer />
          <CreditCardInvoice 
            title="Fatura total"
            availableLimit={availableLimit}
            usedPercentage={usedPercentage}
            currentBalance={currentBalance}
            isLimitProgressVisible
          />
        </Flex>
        <Table<any>
            columns={columns as ColumnsType}
            dataSource={creditCards}
            rowKey={(record) => record.id}
            onRow={(record) => ({
                onClick: () => {
                  setSelectedCreditCardName(record?.cardName)
                  navigate(`/cartao/${record.cardId}`)
                },
                
            })}
            locale={{ emptyText: "Sem dados de cartoes de crédito." }}
            pagination={false}
            loading={initialDataIsLoading}
        />
      </Flex>
    </>
  );
};

export default WithAuth(ManageCreditCardPage);
