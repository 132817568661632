import axios from "axios";


function getAPIN8N() {
    const n8nApi = axios.create({
        baseURL: `${import.meta.env.VITE_N8N_API_URL}`,
        headers: {
            "X-Token-Api": `0086f09a-7b81-4604-b340-48d7c97ea4ae`,
            "Content-Type": "application/json"
        }
    })
    n8nApi.interceptors.request.use(
        (config) => {
          const newConfig = config;
          newConfig.headers.post['X-Token-Api'] = "0086f09a-7b81-4604-b340-48d7c97ea4ae";
          return newConfig;
        },
        (error) => Promise.reject(error)
      );
    return n8nApi;

}
export const Api_N8N = getAPIN8N();
