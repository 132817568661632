import { Flex, Heading, Badge, Text, Button, Tooltip } from "@chakra-ui/react";
import { useClient, useSettings } from "@/hooks";
import { useEffect, useState } from "react";
import { WithAuth } from "@/hoc";
import { NewContainer } from "@/layouts/SejaPago/NewContainer";
import { Input as ChakraInput, Select } from "@/components";
import { useForm } from "react-hook-form";
import { clearMask, cpf_cnpj, formatCep, getNextLetter, showToast, getStatus, phone_format, formatTextDate, formatDateText, formatKycAnalysisResult, formatPhoneE164, splitFullName } from "@/utils";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { StepComponent } from "./_StepComponent";
import isEmail from 'validator/lib/isEmail';
import isURL from "validator/lib/isURL";
import { PhoneInput2 } from "@/components/Forms/PhoneInput2";
const PjAccount = () => {
    const [owners, setOwners] = useState<string[]>(["A"]);
    const [naturalOwners, setNaturalOwners] = useState<string[]>([]);
    const [legalOwners, setLegalOwners] = useState<string[]>([]);
    const [nextLetter, setNextLetter] = useState<string>("B");
    const [status, setStatus] = useState("INACTIVE");
    const [kycAnalysisResult, setKycAnalysisResult] = useState("");
    const [isCnpjFound, setIsCnpjFound] = useState(false);
    const { getBankingAccount, postBankingAccount } = useSettings();
    const [isCepLoading, setIsCepLoading] = useState(false);
    const [isCepNotFound, setIsCepNotFound] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const { consultCNPJ, consultCep } = useClient();
    const [section, setSection] = useState(1);
    const user = JSON.parse(localStorage.getItem("barte-user-info"));
    const userDocument = localStorage.getItem("barte-user-document");
    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        setError,
        clearErrors,
        watch,
    } = useForm();
    const societyTypes = [    
        {
            title: "Sócio",
            id: 1,
            value: "PARTNER"
        },
        {
            title: "Procurador",
            id: 2,
            value: "PROXY_HOLDER"
        },
        {
            title: "Representante Legal",
            id: 3,
            value: "LEGAL_REPRESENTATIVE"
        },
        {
            title: "Outros",
            id: 4,
            value: "OTHER"
        },
    ]
    const businessType = [    
        {
            title: "MEI",
            id: 1,
            value: "MEI"
        },
        {
            title: "ME",
            id: 2,
            value: "ME"
        },
        {
            title: "EI",
            id: 3,
            value: "EI"
        },
        {
            title: "EIRELI",
            id: 4,
            value: "EIRELI"
        },
        {
            title: "LTDA",
            id: 5,
            value: "LTDA"
        },
        {
            title: "SS",
            id: 6,
            value: "SS"
        },
        {
            title: "SA",
            id: 7,
            value: "SA"
        },
        {
            title: "Sem Fins Lucrativos",
            id: 8,
            value: "NON_PROFIT"
        },
    ]
    
    async function getInitialData() {
        try {
            setValue("businessType", "LTDA");
            setValue("ownerType-A", "PARTNER");
            setValue("companyDocument", cpf_cnpj(userDocument));
            const response = await getBankingAccount();
            if(response?.data) {
                const { data } = response;
                const { accountInput, status: accountStatus, kycAnalysisResult } = data;
                setStatus(accountStatus);
                setKycAnalysisResult(kycAnalysisResult);
                if (accountInput) {
                    const { 
                        fantasyName, url, businessType, mainActivity, phone, openingDate, email,
                        newBankingAddress, newAccountOwner 
                    } = accountInput;
                    setValue("fantasyName", fantasyName);
                    setValue("url", url);
                    setValue("businessType", businessType);
                    setValue("mainActivity", mainActivity);
                    setValue("phone", phone);
                    setValue("openingDate", formatDateText(openingDate));
                    setValue("mail", email);
                    if (newBankingAddress) {
                        setValue("zipCode", formatCep(newBankingAddress.zipCode));
                        setValue("street", newBankingAddress.street);
                        setValue("number", newBankingAddress.number);
                        setValue("complement", newBankingAddress.complement);
                        setValue("district", newBankingAddress.district);
                        setValue("city", newBankingAddress.city);
                        setValue("state", newBankingAddress.state);
                    }
                    if (newAccountOwner) {
                        const { firstName, lastName, address } = newAccountOwner;
                        setValue("ownerDocument-A", newAccountOwner.document);
                        setValue("ownerDocumentType-A", "cpf");
                        setValue("ownerFullName-A", `${firstName} ${lastName}`);
                        setValue("ownerEmail-A", newAccountOwner.email);
                        setValue("ownerPhone-A", newAccountOwner.phoneNumber);
                        setValue("ownerBirthDate-A", formatDateText(newAccountOwner.birthDate));
                        if (address) {
                            setValue("ownerZipCode-A", address.zipCode);
                            setValue("ownerStreet-A", address.street);
                            setValue("ownerState-A", address.state);
                            setValue("ownerNumber-A", address.number);
                            setValue("ownerCity-A", address.city);
                            setValue("ownerCountry-A", address.country);
                            setValue("ownerComplement-A", address.complement);
                            setValue("ownerDistrict-A", address.district);
                        }
                    }
                    naturalOwners.push("A");
                }
            }
        } catch (error) {
            try {
                const { data } = await consultCNPJ(userDocument);
                setIsCnpjFound(true);
                setValue("companyDocument", cpf_cnpj(userDocument));
                setValue("companyName", data.companyName);
                if (data.address) {
                    setValue("zipCode", formatCep(data.address.zipCode));
                    setValue("street", data.address.street);
                    setValue("number", data.address.number);
                    setValue("complement", data.address.complement);
                    setValue("district", data.address.district);
                    setValue("city", data.address.city);
                    setValue("state", data.address.state);
                }
            } catch (cnpjError) {
                setIsCnpjFound(false);
                setValue("companyDocument", cpf_cnpj(userDocument));
                showToast({
                    type: "error",
                    message: "Erro ao consultar CNPJ. Por favor, preencha os dados manualmente.",
                });
            }
        }
    }
    const submitForm = async() => {
        const values = getValues();
        setIsSubmitLoading(true);
        const { firstName, lastName } = splitFullName(values?.[`ownerFullName-A`]);
        const formData = {
            fantasyName: values?.fantasyName,
            url: values?.url,
            openingDate: formatTextDate(values?.openingDate),
            businessType: values?.businessType,
            mainActivity: values?.mainActivity,
            phone: clearMask(values?.phone ?? values?.[`ownerPhone-A`]),
            email: values?.mail,
            naturalOwner: naturalOwners?.map(no => {
                const { firstName, lastName } = splitFullName(values?.[`ownerFullName-${no}`]);
                return {
                    document: clearMask(values?.[`ownerDocument-${no}`]),
                    firstName,
                    lastName,
                    birthdate: formatTextDate(values?.[`ownerBirthDate-${no}`]),
                    email: values?.[`ownerEmail-${no}`],
                    phone: clearMask(values?.[`ownerPhone-${no}`]),
                    type: values?.[`ownerType-${no}`],
                    address: {
                        zipCode: values?.[`ownerZipCode-${no}`],
                        street: values?.[`ownerStreet-${no}`],
                        number: values?.[`ownerNumber-${no}`],
                        complement: values?.[`ownerComplement-${no}`],
                        district: values?.[`ownerDistrict-${no}`],
                        city: values?.[`ownerCity-${no}`],
                        state: values?.[`ownerState-${no}`],
                        country: "BR"
                    }
                }
            }),
            legalOwner: legalOwners?.map(lo => {
                return {
                    document: clearMask(values?.[`ownerDocument-${lo}`]),
                    legalName: values?.[`ownerLegalName-${lo}`],
                    foundingDate: formatTextDate(values?.[`ownerFoundingDate-${lo}`]),
                    email: values?.[`ownerEmail-${lo}`],
                    phone: clearMask(values?.[`ownerPhone-${lo}`]),
                    type: values?.[`ownerType-${lo}`],
                    address: {
                        zipCode: values?.[`ownerZipCode-${lo}`],
                        street: values?.[`ownerStreet-${lo}`],
                        number: values?.[`ownerNumber-${lo}`],
                        complement: values?.[`ownerComplement-${lo}`],
                        district: values?.[`ownerDistrict-${lo}`],
                        city: values?.[`ownerCity-${lo}`],
                        state: values?.[`ownerState-${lo}`],
                        country: "BR"
                    }
                }
            }),
            address: {
                zipCode: values?.zipCode,
                street: values?.street,
                number: values?.number,
                complement: values?.complement ?? "",
                district: values?.district,
                city: values?.city,
                state: values?.state,
                country: "BR"
            },
            accountOwner: {
                document: clearMask(values?.[`ownerDocument-A`]),
                firstName,
                lastName,
                birthdate: formatTextDate(values?.[`ownerBirthDate-A`]),
                email: values?.[`ownerEmail-A`],
                phoneNumber: clearMask(values?.[`ownerPhone-A`]),
                address: {
                    zipCode: values?.[`ownerZipCode-A`],
                    street: values?.[`ownerStreet-A`],
                    number: values?.[`ownerNumber-A`],
                    complement: values?.[`ownerComplement-A`],
                    district: values?.[`ownerDistrict-A`],
                    city: values?.[`ownerCity-A`],
                    state: values?.[`ownerState-A`],
                    country: "BR"
                }
            }
        }
        try {
            await postBankingAccount(formData);
            setStatus("PENDING");
            showToast({
                type: "success",
                message: "Conta enviada com sucesso. Aguarde o processamento da mesma.",
            });
            setSection(1);
        } catch(error) {
            error?.response?.data?.errors.map((error) => {
                showToast({
                  type: "error",
                  message: `${error?.code} - ${error?.description}`
                })
            });
        } finally {
            setIsSubmitLoading(false);
        }
    }
    const updateOwners = async() => {
        const updateOwners = [...owners];
        updateOwners.push(nextLetter);
        setOwners(updateOwners);
        setNextLetter(getNextLetter(nextLetter));
    }
    const validateForm = () => {
        if(status !== "INACTIVE" && status !== "FAILED" && status !== "PENDING") {
            setSection(section + 1);
            return
        }
        const sectionOneFields = ["companyDocument", "companyName", "openingDate" , "mail", "zipCode", "street", "number", "district", "city", "state"];
        const sectionTwoFields = ["fantasyName", "url", "businessType", "mainActivity"];
        const sectionThreeFieldsNaturalOwner = ["ownerEmail", "ownerPhone", "ownerDocument", "ownerFullName", "ownerType", "ownerBirthDate", "ownerZipCode", "ownerStreet", "ownerNumber", "ownerDistrict", "ownerCity", "ownerState"];
        const sectionThreeFieldsLegalOwner = ["ownerEmail", "ownerPhone", "ownerDocument", "ownerLegalName", "ownerType", "ownerFoundingDate", "ownerZipCode", "ownerStreet", "ownerNumber", "ownerDistrict", "ownerCity", "ownerState"];
        let errorsSectionOne = [];
        let errorsSectionTwo = [];
        let errorsSectionTree = [];
        if(section === 1) {
            sectionOneFields.map(field => {
                const state = getValues(field);
                if(state === undefined || state === "") {
                    errorsSectionOne.push({field: field, message: "Peenchimento obrigatório!"});
                    return
                }
                if(field === "mail" && !isEmail(state)) {
                    errorsSectionOne.push({field: field, message: "E-mail inválido!"});
                    return
                }
            })
            if(errorsSectionOne?.length > 0) {
                errorsSectionOne.map(field => {
                    setError(field.field, { type: "custom", message: field.message });
                })
                return
            }
            setSection(2);
            errorsSectionOne = [];
            clearErrors();
            return
        }
        if(section === 2) {
            sectionTwoFields.map(field => {
                const state = getValues(field);
                if(state === undefined || state === "") {
                    errorsSectionTwo.push({field: field, message: "Peenchimento obrigatório!"});
                    return
                }
                if(field === "url" && !isURL(state)) {
                    errorsSectionTwo.push({field: field, message: "Digite uma url válida!"});
                    return
                }
            })
            if(errorsSectionTwo?.length > 0) {
                errorsSectionTwo.map(field => {
                    setError(field.field, { type: "custom", message: field.message });
                })
                return
            }
            setSection(3);
            errorsSectionTwo = [];
            clearErrors();
            return
        }
        if(section === 3) {
            owners.forEach(no => {
                if(legalOwners.includes(no)) {
                    sectionThreeFieldsLegalOwner.map(field => {
                        const state = getValues(`${field}-${no}`);
                        if(state === undefined || state === "") {
                            errorsSectionTree.push(`${field}-${no}`);
                        }
                    })
                }
                if(naturalOwners.includes(no)) {
                    sectionThreeFieldsNaturalOwner.map(field => {
                        const state = getValues(`${field}-${no}`);
                        if(state === undefined || state === "") {
                            errorsSectionTree.push(`${field}-${no}`);
                        }
                    })
                }
            })
            if(errorsSectionTree?.length > 0) {
                errorsSectionTree.map(field => {
                    setError(field, { type: "custom", message: "Peenchimento obrigatório!" });
                })
                return
            }
            errorsSectionTree = [];
            clearErrors();
            submitForm();
            return

        }
    }
    async function handleValidateCnpjCpf (owner) {
        const updateNaturalOwners = [...naturalOwners];
        const updateLegalOwners = [...legalOwners];
        clearErrors(`ownerDocument-${owner}`);
        if(watch(`ownerDocument-${owner}`).length <= 11) {
            if(!cpf.isValid(clearMask(watch(`ownerDocument-${owner}`)))) {
                setError(`ownerDocument-${owner}`, { type: "custom", message: "Cpf inválido." });
            }
            setValue(`ownerDocumentType-${owner}`, "cpf");
            if(updateNaturalOwners.includes(owner)) return;
            if(updateLegalOwners.includes(owner)) {
                const filteredLegalOwners = updateLegalOwners.filter(ulo => ulo !== owner);
                setLegalOwners(filteredLegalOwners);
            }
            updateNaturalOwners.push(owner);
            setNaturalOwners(updateNaturalOwners);
            return
        }
        if(!cnpj.isValid(clearMask(watch(`ownerDocument-${owner}`)))) {
            setError(`ownerDocument-${owner}`, { type: "custom", message: "Cnpj inválido." });
            return
        }
        if(updateLegalOwners.includes(owner)) return;
        if(updateNaturalOwners.includes(owner)) {
            const filteredNaturalOwners = updateNaturalOwners.filter(ulo => ulo !== owner);
            setNaturalOwners(filteredNaturalOwners);
        }
        updateLegalOwners.push(owner);
        setLegalOwners(updateLegalOwners);
        setValue(`ownerDocumentType-${owner}`, "cnpj");
    }
    async function handleGetCep(owner?: string) {
        if(owner) {
            try {
                clearErrors(`ownerZipCode-${owner}`)
                setIsCepLoading(true);
                const { data } = await consultCep(clearMask(watch(`ownerZipCode-${owner}`)));
                setValue(`ownerStreet-${owner}`, data.logradouro);
                setValue(`ownerDistrict-${owner}`, data.bairro);
                setValue(`ownerCity-${owner}`, data.localidade);
                setValue(`ownerState-${owner}`, data.uf);
                setValue(`ownerComplement-${owner}`, data.complemento);
                setValue(`ownerContry-${owner}`, "BR");
                setIsCepNotFound(false);
            } catch {
                if (watch(`ownerZipCode-${owner}`).length !== 10) return setError(`ownerZipCode-${owner}`, { type: "custom", message: "Cep inválido." });
                setIsCepNotFound(true);
            } finally {
                setIsCepLoading(false);
            } 
            return
        }
        try {
            clearErrors("zipCode")
            setIsCepLoading(true);
            const { data } = await consultCep(clearMask(watch("zipCode")));
            setValue("street", data.logradouro);
            setValue("district", data.bairro);
            setValue("city", data.localidade);
            setValue("state", data.uf);
            setValue("complement", data.complemento);
            setValue("country", "BR");
            setIsCepNotFound(false);
        } catch {
            if (watch("zipCode").length !== 10) return setError("zipCode", { type: "custom", message: "Cep inválido." });
            setIsCepNotFound(true);
        } finally {
            setIsCepLoading(false);
        }
    }
    useEffect(() => {
        getInitialData()
    }, [user.document])

    return (
        <>
        <Flex
            w="100%"
            flexDir="column"
            padding="2rem 6rem"
            gap="1rem"
        >
            <Flex
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex
                    gap="8px"
                >
                    <Heading fontSize="1.5rem">Ativar Conta PJ</Heading>
                    <Badge 
                        variant="yellowBills"
                        borderRadius="40px"
                        alignItems="center"
                        justifyContent="center"
                        color="gold.8"
                    >
                        {getStatus(status)}
                    </Badge>
                    
                </Flex>

                <Flex
                    padding="10px 16px 10px 16px"
                    backgroundColor="#E6F7FF"
                >
                    <Text 
                        color="#003A8C" 
                        fontSize=".8rem"
                    >
                        Ative sua conta PJ e mantenha sua conta da Barte fucionando
                    </Text>
                </Flex>

            </Flex>
            <StepComponent 
                section={section} 
                status={status} 
            />
            {section === 1 && (
                <>
                    <Flex
                        border="1px solid #EDF1F7"
                    >
                        <NewContainer
                            type="check"
                            w="100%"
                            title="Informações gerais"
                            startsOpen
                            p="25px"
                        >
                                <ChakraInput
                                    label="CNPJ"
                                    type="text"
                                    placeholder="00.X01.000/0001-10"
                                    name="document"
                                    register={{ ...register("companyDocument") }}
                                    errors={errors?.companyDocument}
                                    isDisabled={isCnpjFound || (status === "PENDING"  || status === "ACTIVE")}
                                />
                                <ChakraInput
                                    label="Razão social"
                                    type="text"
                                    placeholder=""
                                    name="companyName"
                                    register={{ ...register("companyName") }}
                                    errors={errors?.companyName}
                                    isDisabled={isCnpjFound || (status === "PENDING"  || status === "ACTIVE")}
                                />
                                <ChakraInput
                                    label="Data de abertura"
                                    type="text"
                                    placeholder="00/00/0000"
                                    name="openingDate"
                                    register={{ ...register("openingDate") }}
                                    errors={errors?.openingDate}
                                    mask="99/99/9999"
                                    max={10}
                                    isDisabled={status === "PENDING"  || status === "ACTIVE"}
                                />
                                <ChakraInput
                                    label="E-mail"
                                    type="text"
                                    id="mail"
                                    name="mail"
                                    register={{ ...register("mail") }}
                                    errors={errors?.mail}
                                    isDisabled={status === "PENDING"  || status === "ACTIVE"}
                                />
                                <PhoneInput2 
                                    type="text"
                                    name="phone"
                                    label="Telefone (opcional)"
                                    valueData={formatPhoneE164(watch("phone"))}
                                    setValue={(value) => setValue("phone", value)}
                                    isDisabled={status === "PENDING"  || status === "ACTIVE"}
                                />
                        </NewContainer>
                    </Flex>
                    <Flex
                        border="1px solid #EDF1F7"
                    >
                        <NewContainer
                            type="check"
                            w="100%"
                            title="Endereço da empresa"
                            startsOpen
                            p="25px"
                        >
                                <ChakraInput
                                    label="CEP"
                                    type="text"
                                    placeholder="23000-X0"
                                    name="zipCode"
                                    register={{ ...register("zipCode") }}
                                    errors={errors?.zipCode}
                                    mask="99999-999"
                                    onBlur={() => handleGetCep()}
                                    isDisabled={status === "PENDING"  || status === "ACTIVE"}
                                />
                                <ChakraInput
                                    label="Rua"
                                    type="text"
                                    placeholder=""
                                    name="street"
                                    register={{ ...register("street") }}
                                    errors={errors?.street}
                                    isDisabled={isCepLoading || (status === "PENDING"  || status === "ACTIVE")}
                                />
                                <Flex
                                    gap="1rem"
                                >
                                    <ChakraInput
                                        label="Número"
                                        type="text"
                                        name="number"
                                        register={{ ...register("number") }}
                                        errors={errors?.number}
                                        isDisabled={status === "PENDING"  || status === "ACTIVE"}
                                    />
                                    <ChakraInput
                                        label="Complemento"
                                        type="text"
                                        name="complement"
                                        register={{ ...register("complement") }}
                                        errors={errors?.complement}
                                        isDisabled={isCepLoading || (status === "PENDING"  || status === "ACTIVE")}
                                    />
                                </Flex>
                                <ChakraInput
                                    label="Bairro"
                                    type="text"
                                    name="district"
                                    register={{ ...register("district") }}
                                    errors={errors?.district}
                                    isDisabled={isCepLoading ||(status === "PENDING"  || status === "ACTIVE")}
                                />
                                <Flex
                                    gap="1rem"
                                >
                                    <ChakraInput
                                        label="Cidade"
                                        type="text"
                                        name="city"
                                        register={{ ...register("city") }}
                                        errors={errors?.city}
                                        isDisabled={isCepLoading ||(status === "PENDING"  || status === "ACTIVE")}
                                    />
                                    <ChakraInput
                                        label="Estado"
                                        type="text"
                                        name="state"
                                        register={{ ...register("state") }}
                                        errors={errors?.state}
                                        isDisabled={isCepLoading ||(status === "PENDING"  || status === "ACTIVE")}
                                    />
                                </Flex>
                        </NewContainer>
                    </Flex>
                </>
            )}
            {section === 2 && (
                <>
                    <Flex
                        border="1px solid #EDF1F7"
                    >
                        <NewContainer
                            type="check"
                            w="100%"
                            title="Informações gerais"
                            startsOpen
                            p="25px"
                        >
                                <ChakraInput
                                    label="Nome fantasia"
                                    type="text"
                                    name="fantasyName"
                                    register={{ ...register("fantasyName") }}
                                    errors={errors?.fantasyName}
                                    isDisabled={status === "ACTIVE"}
                                />
                                <ChakraInput
                                    label="Website"
                                    type="text"
                                    placeholder=""
                                    name="url"
                                    register={{ ...register("url") }}
                                    errors={errors?.url}
                                    isDisabled={status === "ACTIVE"}
                                />
                                <Select
                                    w="100%"
                                    name="businessType"
                                    label="Categoria de empresa"
                                    defaultValue="LTDA"
                                    onChange={(e) => setValue("businessType", e.currentTarget.value)}
                                    optionsList={businessType}
                                    isDisabled={status === "ACTIVE"}
                                />
                                <ChakraInput
                                    label="CNAE"
                                    type="text"
                                    name="mainActivity"
                                    register={{ ...register("mainActivity") }}
                                    errors={errors?.mainActivity}
                                    isDisabled={status === "ACTIVE"}
                                    mask="999999999"
                                />
                        </NewContainer>
                    </Flex>
                </>
            )}
            {section === 3 && (
                <>
                        {owners.map(no => (
                            <Flex
                                border="1px solid #EDF1F7"
                            >
                                <NewContainer
                                    key={no}
                                    type="check"
                                    w="100%"
                                    title={`Sócio ${no}`}
                                    startsOpen={no === "A"}
                                    p="25px"
                                >
                                        <ChakraInput
                                            label="Documento"
                                            type="text"
                                            placeholder={no === "A" ? "CPF" : "CNPJ/CPF"}
                                            mask="cpf/cnpj"
                                            maxLength={no === "A" ? 11 : 14}
                                            name={`ownerDocument-${no}`}
                                            register={{ ...register(`ownerDocument-${no}`) }}
                                            errors={errors?.[`ownerDocument-${no}`]}
                                            onBlur={() => handleValidateCnpjCpf(no)}
                                            isDisabled={status === "ACTIVE"}
                                        />
                                        {watch(`ownerDocumentType-${no}`) === "cnpj" && (
                                            <ChakraInput
                                                label="Nome"
                                                type="text"
                                                placeholder="Razão Social"
                                                name={`ownerLegalName-${no}`}
                                                register={{ ...register(`ownerLegalName-${no}`) }}
                                                errors={errors?.[`ownerLegalName-${no}`]}
                                                isDisabled={status === "ACTIVE"}
                                            />
                                        )}
                                        {watch(`ownerDocumentType-${no}`) === "cpf" && (
                                            <ChakraInput
                                                label="Nome completo"
                                                type="text"
                                                placeholder="João da Silva Santos"
                                                name={`ownerFullName-${no}`}
                                                register={{ ...register(`ownerFullName-${no}`) }}
                                                errors={errors?.[`ownerFullName-${no}`]}
                                                isDisabled={status === "ACTIVE"}
                                                tooltipText="Não use abreviações, como J.S.S ou J.S.S.S"
                                            />
                                        )}
                                        <Select
                                            w="100%"
                                            name={`ownerType-${no}`}
                                            label="Tipo de sociedade"
                                            defaultValue="PARTNER"
                                            onChange={(e) => setValue(`ownerType-${no}`, e.currentTarget.value)}
                                            optionsList={societyTypes}
                                            isDisabled={status === "ACTIVE"}
                                        />
                                        {watch(`ownerDocumentType-${no}`) === "cpf" && (
                                            <ChakraInput
                                                label="Data de nascimento"
                                                placeholder="dd/mm/aaaa"
                                                mask="99/99/9999"
                                                type="text"
                                                name={`ownerBirthDate-${no}`}
                                                register={{ ...register(`ownerBirthDate-${no}`) }}
                                                errors={errors?.[`ownerBirthDate-${no}`]}
                                                isDisabled={status === "ACTIVE"}
                                            />
                                        )}
                                        {watch(`ownerDocumentType-${no}`) === "cnpj" && (
                                            <ChakraInput
                                                label="Data de abertura"
                                                placeholder="dd/mm/aaaa"
                                                mask="99/99/9999"
                                                type="text"
                                                name={`ownerFoundingDate-${no}`}
                                                register={{ ...register(`ownerFoundingDate-${no}`) }}
                                                errors={errors?.[`ownerFoundingDate-${no}`]}
                                                isDisabled={status === "ACTIVE"}
                                            />
                                        )}
                                        
                                        <ChakraInput
                                            label="Email"
                                            type="text"
                                            placeholder="Seu email"
                                            name={`ownerEmail-${no}`}
                                            register={{ ...register(`ownerEmail-${no}`) }}
                                            errors={errors?.[`ownerEmail-${no}`]}
                                            isDisabled={status === "ACTIVE"}
                                        />
                                        <PhoneInput2 
                                            type="text"
                                            name="phone"
                                            label="Telefone"
                                            valueData={formatPhoneE164(watch(`ownerPhone-${no}`))}
                                            setValue={(value) => setValue(`ownerPhone-${no}`, value)}
                                            isDisabled={status === "ACTIVE"}
                                        />
                                        <ChakraInput
                                            label="CEP"
                                            type="text"
                                            placeholder="00000-X00"
                                            name={`ownerZipCode-${no}`}
                                            mask="99999-999"
                                            register={{ ...register(`ownerZipCode-${no}`) }}
                                            errors={errors?.[`ownerZipCode-${no}`]}
                                            onBlur={() => handleGetCep(no)}
                                            isDisabled={status === "ACTIVE"}
                                        />
                                        <ChakraInput
                                            label="Rua"
                                            type="text"
                                            placeholder=""
                                            name={`ownerStreet-${no}`}
                                            register={{ ...register(`ownerStreet-${no}`) }}
                                            errors={errors?.[`ownerStreet-${no}`]}
                                            isDisabled={isCepLoading || status === "ACTIVE"}
                                        />
                                        <Flex
                                            gap="1rem"
                                        >
                                            <ChakraInput
                                                label="Número"
                                                type="text"
                                                name={`ownerNumber-${no}`}
                                                register={{ ...register(`ownerNumber-${no}`) }}
                                                errors={errors?.[`ownerNumber-${no}`]}
                                                isDisabled={status !== "INACTIVE"  && status === "ACTIVE"}
                                            />
                                            <ChakraInput
                                                label="Complemento"
                                                type="text"
                                                name={`ownerComplement-${no}`}
                                                register={{ ...register(`ownerComplement-${no}`) }}
                                                errors={errors?.[`ownerComplement-${no}`]}
                                                isDisabled={isCepLoading || status === "ACTIVE"}
                                            />
                                        </Flex>
                                        <ChakraInput
                                            label="Bairro"
                                            type="text"
                                            name={`ownerDistrict-${no}`}
                                            register={{ ...register(`ownerDistrict-${no}`) }}
                                            errors={errors?.[`ownerDistrict-${no}`]}
                                            isDisabled={isCepLoading || status === "ACTIVE"}
                                        />
                                        <Flex
                                            gap="1rem"
                                        >
                                            <ChakraInput
                                                label="Cidade"
                                                type="text"
                                                name={`ownerCity-${no}`}
                                                register={{ ...register(`ownerCity-${no}`) }}
                                                errors={errors?.[`ownerCity-${no}`]}
                                                isDisabled={!isCepNotFound}
                                            />
                                            <ChakraInput
                                                label="Estado"
                                                type="text"
                                                name={`ownerState-${no}`}
                                                register={{ ...register(`ownerState-${no}`) }}
                                                errors={errors?.[`ownerState-${no}`]}
                                                isDisabled={!isCepNotFound}
                                            />
                                        </Flex>
                                </NewContainer>
                            </Flex>
                        ))}

                    <NewContainer
                        type="check"
                        w="100%"
                        title={`Sócio ${nextLetter}`}
                        p="25px"
                        startsOpen={false}
                        button
                        buttonAction={updateOwners}
                        buttonTitle="+ Adicionar sócio"
                    >
                            
                    </NewContainer>
                </>
            )}
            <Flex
                marginLeft="auto" 
                gap="8px"
            >
                {section > 1 && (
                    <Button 
                        variant="cancel"
                        onClick={() => setSection(section - 1)}
                    >
                        Voltar
                    </Button>
                )}
                {section < 3 && (
                    <Button 
                        variant="primary"
                        onClick={() => {
                            validateForm()
                        }}
                    >
                        Próximo
                    </Button>
                )}
                {section === 3 && (
                    <Button 
                        variant="primary"
                        onClick={() => {
                            validateForm()
                        }}
                        isLoading={isSubmitLoading}
                        isDisabled={isSubmitLoading}
                    >
                        Finalizar
                    </Button>
                )}
            </Flex>
        </Flex>
        </>
    );
};

export default WithAuth(PjAccount);
