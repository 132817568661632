import { IconProps } from "@/models/Icon";

export function SecondStepIcon({
    size = 18,
    color = "#000",
}: IconProps) {
    return (

        <svg width={size }height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0004 2.64996C8.04227 2.64996 2.40039 8.29184 2.40039 15.25C2.40039 22.2081 8.04227 27.85 15.0004 27.85C21.9585 27.85 27.6004 22.2081 27.6004 15.25C27.6004 8.29184 21.9585 2.64996 15.0004 2.64996ZM15.0004 25.7125C9.22352 25.7125 4.53789 21.0268 4.53789 15.25C4.53789 9.47309 9.22352 4.78746 15.0004 4.78746C20.7773 4.78746 25.4629 9.47309 25.4629 15.25C25.4629 21.0268 20.7773 25.7125 15.0004 25.7125Z" fill="black"/>
        <path d="M4.53906 15.2499C4.53906 9.47307 9.22469 4.78745 15.0016 4.78745C20.7784 4.78745 25.4641 9.47307 25.4641 15.2499C25.4641 21.0268 20.7784 25.7124 15.0016 25.7124C9.22469 25.7124 4.53906 21.0268 4.53906 15.2499Z" fill="black"/>
        <path d="M10.6193 20.8852V19.0239L14.9685 14.9969C15.3384 14.6389 15.6486 14.3168 15.8991 14.0304C16.1537 13.744 16.3466 13.4636 16.4778 13.1892C16.6091 12.9108 16.6747 12.6105 16.6747 12.2884C16.6747 11.9304 16.5932 11.6222 16.4301 11.3636C16.267 11.1011 16.0443 10.9003 15.7619 10.7611C15.4795 10.6179 15.1594 10.5463 14.8014 10.5463C14.4276 10.5463 14.1014 10.6219 13.823 10.773C13.5446 10.9241 13.3298 11.1409 13.1787 11.4233C13.0276 11.7057 12.952 12.0418 12.952 12.4315H10.5C10.5 11.6321 10.681 10.9381 11.0429 10.3494C11.4048 9.7608 11.9119 9.3054 12.5642 8.98324C13.2165 8.66108 13.9682 8.5 14.8193 8.5C15.6943 8.5 16.456 8.65511 17.1043 8.96534C17.7565 9.27159 18.2636 9.69716 18.6256 10.242C18.9875 10.7869 19.1685 11.4114 19.1685 12.1153C19.1685 12.5767 19.077 13.0321 18.894 13.4815C18.7151 13.931 18.3949 14.4301 17.9335 14.979C17.4722 15.5239 16.8219 16.1781 15.9827 16.9418L14.1989 18.6898V18.7733H19.3295V20.8852H10.6193Z" fill="white"/>
        </svg>
        

    )
}


















