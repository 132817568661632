import { 
    AlertDialog, 
    AlertDialogBody, 
    AlertDialogContent, 
    AlertDialogFooter, 
    AlertDialogHeader, 
    AlertDialogOverlay, 
    Button, 
    Divider, 
    Flex, 
    Text
} from "@chakra-ui/react";
import { useCreditCard } from "@/hooks/useCreditCard";
import { useRef } from "react";
import { CreditCardIcon } from "@/styles/icons/CreditCardIcon";
import { CreditCard } from "../cartao/_CreditCard";
import { copyTextToClipboard, formatCreditCard } from "@/utils";

interface SeeCreditCardProps {
    isOpen: boolean;
    onClose: ()=> void;
}
export default function SeeCreditCard({isOpen, onClose}: SeeCreditCardProps) {
  const { creditCardData, selectedCreditCardName } = useCreditCard();
  const modalRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={modalRef}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          display="flex"
          flexDir="column"
          borderRadius=".5rem"
          w="330px"
        >
            <AlertDialogHeader
                p="16px 24px" 
                fontSize="1rem" 
                fontWeight="bold"
            >
                <Flex
                    align="center"
                    gap="4"
                >
                    <CreditCardIcon />
                    <Text
                        fontSize="1rem"
                        fontWeight={600}
                    >
                        Dados do cartão
                    </Text>
                </Flex>
            </AlertDialogHeader>
            <Divider />
            <AlertDialogBody
                margin="0"
                px="1rem"
                py="1rem"
                w="100%"
            >
                <Flex
                    w="100%"
                    flexDirection="column"
                    gap="1rem"
                >
                    <Text>
                        Cartão: {selectedCreditCardName}
                    </Text>
                    <CreditCard 
                        cardNumber={formatCreditCard(creditCardData?.pan)}
                        cvv={creditCardData?.cvv}
                        expiryDate={creditCardData?.expiryMmYyyy}
                        holderName={selectedCreditCardName}
                    />
                    <Button
                        variant="primary2"
                        size="sm"
                        borderRadius=".5rem"
                        fontWeight={400}
                        transition="filter .2s"
                        _hover={{
                            transition: "filter .2s",
                            filter: "brightness(.9)",
                        }}
                        _focus={{}}
                        onClick={() => {
                            copyTextToClipboard(creditCardData?.pan, "Número do cartão copiado para a área de transferência!");
                        }}
                    >
                        Copiar número do cartão
                    </Button>
                </Flex>
            </AlertDialogBody>
            <Divider />
            <AlertDialogFooter
                h="5rem"
                w="100%"
                display="flex"
            >
                <Button
                    variant="secondary"
                    borderRadius="8px"
                    transition="filter .2s"
                    _hover={{
                        transition: "filter .2s",
                        filter: "brightness(.9)",
                    }}
                    _active={{
                        filter: "brightness(.6)",
                    }}
                    _focus={{}}
                    onClick={onClose}
                >
                    Ok
                </Button>
            </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
