import { IconProps } from "@/models/Icon";

export function ThirdStep({
    size = 18,
    color = "#000",
}: IconProps) {
    return (

        <svg width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0004 3.1499C8.04227 3.1499 2.40039 8.79178 2.40039 15.7499C2.40039 22.708 8.04227 28.3499 15.0004 28.3499C21.9585 28.3499 27.6004 22.708 27.6004 15.7499C27.6004 8.79178 21.9585 3.1499 15.0004 3.1499ZM15.0004 26.2124C9.22352 26.2124 4.53789 21.5268 4.53789 15.7499C4.53789 9.97303 9.22352 5.2874 15.0004 5.2874C20.7773 5.2874 25.4629 9.97303 25.4629 15.7499C25.4629 21.5268 20.7773 26.2124 15.0004 26.2124Z" fill="black" />
            <path d="M4.53906 15.75C4.53906 9.9731 9.22469 5.28748 15.0016 5.28748C20.7784 5.28748 25.4641 9.9731 25.4641 15.75C25.4641 21.5268 20.7784 26.2125 15.0016 26.2125C9.22469 26.2125 4.53906 21.5268 4.53906 15.75Z" fill="black" />
            <path d="M15.1355 21.1022C14.2446 21.1022 13.4511 20.9491 12.7551 20.6429C12.0631 20.3326 11.5162 19.9071 11.1145 19.3661C10.7168 18.8213 10.5119 18.1929 10.5 17.4809H13.1011C13.117 17.7792 13.2145 18.0417 13.3935 18.2684C13.5764 18.4912 13.819 18.6642 14.1213 18.7875C14.4236 18.9108 14.7636 18.9724 15.1415 18.9724C15.5352 18.9724 15.8832 18.9028 16.1855 18.7636C16.4878 18.6244 16.7244 18.4315 16.8955 18.1849C17.0665 17.9383 17.152 17.6539 17.152 17.3318C17.152 17.0056 17.0605 16.7173 16.8776 16.4667C16.6986 16.2122 16.4401 16.0133 16.102 15.8701C15.7679 15.7269 15.3702 15.6554 14.9088 15.6554H13.7693V13.7582H14.9088C15.2986 13.7582 15.6426 13.6906 15.9409 13.5554C16.2432 13.4201 16.4778 13.2332 16.6449 12.9946C16.8119 12.7519 16.8955 12.4696 16.8955 12.1474C16.8955 11.8412 16.8219 11.5727 16.6747 11.342C16.5315 11.1073 16.3287 10.9244 16.0662 10.7931C15.8077 10.6619 15.5054 10.5963 15.1594 10.5963C14.8094 10.5963 14.4892 10.6599 14.1989 10.7872C13.9085 10.9105 13.6759 11.0875 13.5009 11.3181C13.3259 11.5488 13.2324 11.8193 13.2205 12.1295H10.7446C10.7565 11.4255 10.9574 10.8051 11.3472 10.2681C11.7369 9.73121 12.2619 9.3116 12.9222 9.00933C13.5864 8.70308 14.3361 8.54996 15.1713 8.54996C16.0145 8.54996 16.7523 8.70308 17.3847 9.00933C18.017 9.31558 18.5082 9.72922 18.8582 10.2502C19.2122 10.7673 19.3872 11.348 19.3832 11.9923C19.3872 12.6764 19.1744 13.2471 18.7449 13.7045C18.3193 14.1619 17.7645 14.4522 17.0804 14.5755V14.671C17.9793 14.7863 18.6634 15.0985 19.1327 15.6076C19.606 16.1127 19.8406 16.7451 19.8366 17.5048C19.8406 18.2008 19.6398 18.8193 19.2341 19.3602C18.8324 19.9011 18.2776 20.3267 17.5696 20.6369C16.8616 20.9471 16.0503 21.1022 15.1355 21.1022Z" fill="white" />
        </svg>

    )
}


















