import { ReactNode } from "react";
import {
  AlertDialog,
  AlertProps as AlertPropsChakra,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
  Text,
  Link,
} from "@chakra-ui/react";
import { copyTextToClipboard } from "@/utils";
import { CopyToClipboardIcon } from "@/styles/icons/CopyToClipboardIcon";
import { CheckIcon } from "@/styles/icons/CheckIcon";
import { qrCode } from "@/utils/generateQrCode";

interface AlertProps extends AlertPropsChakra {
  isConfirmAlert?: boolean;
  closeButtonText?: string;
  confirmButtonText?: string;
  isOpen: boolean;
  leastDestructiveRef: any;
  onClose?: () => void;
  onConfirm: () => void;
  title: string;
  messageBody?: string | ReactNode;
  urlBody?: string;
  children?: ReactNode;
  isLoading?: boolean;
  checkBox?: boolean;
  checkBoxValue?: boolean;
  checkBoxText?: string;
  checkBoxFunction?: (param?: boolean) => void;
  generateQrCodeFromUrl?: boolean;
}

function NewAlert({
  urlBody,
  isConfirmAlert,
  children,
  closeButtonText,
  confirmButtonText,
  isOpen,
  onClose,
  onConfirm,
  leastDestructiveRef,
  title,
  isLoading = false,
  checkBox,
  checkBoxFunction,
  checkBoxText,
  checkBoxValue,
  generateQrCodeFromUrl = false,
  ...rest
}: AlertProps) {
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          height="450px"
          display="flex"
          flexDir="column"
          borderRadius="2"
          p="20px"
          {...rest}
        >
          <AlertDialogHeader margin="0" p="0 32px 0 16px" fontSize="1rem" fontWeight="bold">
            <Flex marginTop="1rem" ml={4} align="center" justify="center" gap="12px">
              <CheckIcon />
              <Text
                color="#000000"
                fontWeight="600"
                fontSize="16px"
                lineHeight="24px"
              >
                {title}
              </Text>
            </Flex>
          </AlertDialogHeader>

          <AlertDialogBody
            margin="0"
            p="0"
            h="5rem"
            marginTop="1rem"
            display="flex"
            w="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="2rem"
          >
            <Flex
              maxWidth="382px"
              width="100%"
              maxHeight="43px"
              height="100%"
              bgColor="#F4F7FB"
              borderRadius="4px"
              padding="8px"
              gap="17px"
            >
              <Text
                color="#8A94A8"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {urlBody}
              </Text>
              <Link
                w="100%"
                onClick={() => copyTextToClipboard(urlBody)}
                color="blue.200"
              >
                <Flex gap="4px">
                  <CopyToClipboardIcon />
                  Copiar link
                </Flex>
              </Link>
            </Flex>
            <Flex
              w="100%"
              justifyContent="center"
            >
              {generateQrCodeFromUrl && qrCode(urlBody)}
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter
            h="5rem"
            w="100%"
          >
            <Flex w="100%" justify="center">
              <Button
                m="0 0 3rem 0"
                bgColor="blue.200"
                color="white"
                borderRadius="3"
                marginTop="3rem"
                transition="filter .2s"
                _hover={{
                  transition: "filter .2s",
                  filter: "brightness(.9)",
                }}
                _active={{
                  filter: "brightness(.6)",
                }}
                _focus={{}}
                onClick={onConfirm}
                isLoading={isLoading}
              >
                {confirmButtonText}
              </Button>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export { NewAlert };
