import { useCreditCard } from "@/hooks/useCreditCard";
import { LogoBarteCreditCard } from "@/styles/icons/LogoBarteCreditCard";
import { MastercardLogoCreditCard } from "@/styles/icons/MastercardLogoCreditCard";
import { Box, Flex, Text, Badge, SkeletonText } from "@chakra-ui/react";

interface CreditCardProps {
  isVisible?: boolean;
  cardNumber?: string;
  expiryDate?: string;
  holderName?: string;
  cvv?: string;
  type?: string;
}

export function CreditCard({
  isVisible = true,
  cardNumber = "",
  expiryDate = "",
  holderName = "",
  cvv = "",
  type = "",
}: CreditCardProps) {
  const maskCardNumber = (number: string) => {
    const last4Digits = number.slice(-4);
    const maskedPart = "• • • •   • • • •   • • • •   ";
    return maskedPart + last4Digits;
  };
  const maskCvv = () => "• • •";
  const maskExpiryDate = () => "••/••";
  const { creditCardLoading } = useCreditCard();
  return (
    <Box
        w="300px"
        h="185px"
        rounded="20px"
        p="6" 
        bgImage={`url("/images/commons/barte-credit-card-background.png")`}
        bgSize="cover"
        color="white"
        shadow="xl"
    >
      <Flex justify="space-between" align="start">
        <LogoBarteCreditCard />
        {!isVisible && (
            <Badge
                fontSize="sm"
                px="3"
                py="1"
                rounded="full"
                bg="whiteAlpha.200"
                color="white"
            >
                {type}
            </Badge>
        )}
        {isVisible && (  
            <MastercardLogoCreditCard />
        )}
      </Flex>
      <Box mt="8" mb="8">
        <SkeletonText 
          isLoaded={!creditCardLoading} 
          noOfLines={1} 
          skeletonHeight="4" 
          borderRadius="1rem"
        >
          <Text 
              fontSize="1rem" 
              letterSpacing="widest"
          >
            {isVisible ? cardNumber : maskCardNumber(cardNumber)}
          </Text>
        </SkeletonText>
      </Box>
      <Flex 
        justify="space-between" 
        align="end"
      >
        <Box>
            <Text 
                fontSize="10px" 
                opacity="0.7" 
            >
                {isVisible ? expiryDate : maskExpiryDate()}
            </Text>
            <Text 
                fontWeight="light"
            >
                {holderName}
            </Text>
        </Box>
        <Box>
          <Text 
            fontSize="10px" 
            opacity="0.7" 
          >
            CVV
          </Text>
          <Text>{isVisible ? cvv : maskCvv()}</Text>
        </Box>
      </Flex>
    </Box>
  );
}