import {
    Button,
    Flex,
    Stack,
  } from "@chakra-ui/react";
import { useState } from "react";
//   import { Eye, Lock, PlusCircle, ChevronRight } from "lucide-react";
import { FiEye, FiLock, FiPlusCircle } from "react-icons/fi";
import NewCreditCard from "@/pages/cartoes/novo-cartao";
import { CreditCard } from "./_CreditCard";
import SeeCreditCard from "../dados-cartao";
import { useCreditCard } from "@/hooks/useCreditCard";
import BlockCreditCard from "../bloquear-cartao";
import { CreditCardInvoice } from "../fatura-cartao";

  
  export default function CreditCardManagement() {
    const { 
      creditCardData, 
      selectedCreditCardName, 
      creditCardLoading,
      creditCardTransactionsData,
      getTotalValueTransactions
    } = useCreditCard();
    const currentBalance = getTotalValueTransactions(creditCardTransactionsData);
    const [isNewCreditCardFormOpen, setIsNewCreditCardFormOpen] = useState(false);
    const [isSeeCreditCardOpen, setIsSeeCreditCardOpen] = useState(false);
    const [isBlockCreditCardOpen, setIsBlockCreditCardOpen] = useState(false);
    const featureTogglesUser = localStorage?.getItem("barte-features-list-user");
    return (
      <>
        <BlockCreditCard 
          isOpen={isBlockCreditCardOpen}
          onClose={() => setIsBlockCreditCardOpen(!isBlockCreditCardOpen)}
        />
        <SeeCreditCard 
          isOpen={isSeeCreditCardOpen}
          onClose={() => setIsSeeCreditCardOpen(!isSeeCreditCardOpen)}
        />
        <NewCreditCard 
          isOpen={isNewCreditCardFormOpen}
          onClose={() => setIsNewCreditCardFormOpen(!isNewCreditCardFormOpen)}
        />
        <Flex 
          w="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex 
              gap={4}
              alignItems="center"
          >
              <CreditCard
                isVisible={false}
                cardNumber={creditCardData?.pan}
                cvv={creditCardData?.cvv}
                expiryDate={creditCardData?.expiryMmYyyy}
                holderName={selectedCreditCardName}
              />
              <Stack
                  spacing={6}
              >
                  <Button 
                    variant="outline" 
                    leftIcon={<FiEye size={16} />}
                    onClick={() => setIsSeeCreditCardOpen(!isSeeCreditCardOpen)}
                    p="8px"
                    isDisabled={creditCardLoading}
                  >
                      Ver dados
                  </Button>
                  <Button 
                    variant="outline" 
                    leftIcon={<FiLock size={16} />}
                    onClick={() => setIsBlockCreditCardOpen(!isBlockCreditCardOpen)}
                    p="8px"
                    isDisabled={creditCardLoading || featureTogglesUser?.includes("block_items_credit_card")}
                  >
                     {creditCardData.cardStatus === "BLOCKED" ? "Desbloquear" : "Bloquear"}
                  </Button>
                  {/* <Button 
                    variant="outline" 
                    leftIcon={<FiPlusCircle size={16} />}
                    p="8px"
                    onClick={() => setIsNewCreditCardFormOpen(!isNewCreditCardFormOpen)}
                  >
                      Novo cartão
                  </Button> */}
              </Stack>
          </Flex>
          <CreditCardInvoice 
            title="Fatura aberta"
            availableLimit={0}
            currentBalance={currentBalance}
            usedPercentage={0}
          />

        </Flex>
      </>
    );
  }
  