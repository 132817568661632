import { ChargePaginator, Charge, ClientsPaginator } from "@/models";
import { Api } from "@/services";
import { Api_N8N } from "@/services/Api/n8nApiConfig";
import { filterAndPageableToRequest, formatCharge, showToast } from "@/utils";
import { useState } from "react";

export function useCharge() {
  const baseUrl = "/charges";
  const statmentChargeBaseUrl = "/redeems/statement/charge";
  const sellersBaseUrl = "/sellers"
  const invoiceChargeBaseUrl = "/invoices/charge"
  const clientsBaseUrl = "/seller-clients";
  const invoicesBaseUrl = "/barte"
  const [charge, setCharge] = useState({} as any);
  const [invoices, setInvoices] = useState([])

  const getCharges = (filter: object, pageable: object) => {
    return Api.get<ChargePaginator>(
      `${baseUrl}${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const getChargeById = (uuid: string) => {
    return Api.get<Charge>(`${baseUrl}/${uuid}`);
  };

  const getStatmentChargeById = (uuid: string) => {
    return Api.get(`${statmentChargeBaseUrl}/${uuid}`);
  };

  const getClients = (filter: object, pageable: object) => {
    return Api.get<ClientsPaginator>(
      `${clientsBaseUrl}${filterAndPageableToRequest({ filter, pageable })}`
    );
  };

  const getChargeSubsellers = (uuid: string) => {
    return Api.get(`${baseUrl}/${uuid}/split`);
  }

  const getInvoices = (uuid: string) => {
    return Api.get(`${invoicesBaseUrl}/invoices/charge/${uuid}`)
  }

  const setToPaid = (uuid: string | string[], data: any) => {
    return Api.patch(`${baseUrl}/${uuid}/manual-pay`, data);
  };

  const deleteCharge = (uuid: string) => {
    return Api.delete(`${baseUrl}/${uuid}`);
  };
  const emitInvoice = (uuid: String) => {
    return Api.post(`${baseUrl}/${uuid}/invoices`);
  };

  const sendInvoiceEmail = (uuid: String) => {
    return Api.post(`${baseUrl}/${uuid}/invoice-email`);
  }

  const cancelInvoice = (uuid: String, cnpj: String) => {
    return Api.delete(`${sellersBaseUrl}/invoices/${uuid}/company/${cnpj}`);
  }

  const sendCancelMail = (uuid: String) => {
    return Api_N8N.post(`cartas/cancelamento`, { uuid });
  }


  

  async function getChargeData(id: string) {
    try {
      const chargeResponse = await getChargeById(id);
      const buyerResponse = await getClients({ document: chargeResponse?.data?.customerDocument }, { size: 20 })
      const updatedChargeResponse = {
        ...chargeResponse.data,
        customerId: buyerResponse?.data?.content[0]?.id ?? null,
      }
      const formattedData = formatCharge(updatedChargeResponse);
      setCharge({
        ...formattedData,
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha ao carregar as informações da cobrança.",
      });
    }
  }

  async function getChargeInvoicesData(id: string) {
    try {
      const response = await getInvoices(id);
      setInvoices(response?.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha ao carregar as informações das notas fiscais.",
      });
    }
  }

  async function handleChangeToPaid(id: string, onClose: () => void) {
    try {
      const response = await setToPaid(id, { uuidCharge: charge.uuid });
      const response_charge = await getChargeById(charge.uuid);
      const formattedData = formatCharge(response_charge.data);

      setCharge(formattedData);
      showToast({
        message: "Cobrança alterada para paga manualmente com sucesso!",
        type: "success",
      });

      onClose();
    } catch {
      onClose();
      showToast({
        message: "Erro ao alterar cobrança, tente novamente",
      });
    }
  }

  const chargeBackCharge = (uuid: string, data?: any) => {
    return Api.patch(`${baseUrl}/${uuid}/refund`, data);
  }

  async function handleChargeBackCharge(id: string, setChargebackModalState: (param: boolean) => void, asFraud?: boolean) {
    try {
      const response = await chargeBackCharge(id, {asFraud});
      const response_charge = await getChargeById(charge.uuid);
      const formattedData = formatCharge(response_charge.data);

      showToast({
        type: "success",
        message: "Cobrança estornada com sucesso"
      });
      setChargebackModalState(false);
      setCharge(formattedData);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`
        })
      });
    }
  }

  return {
    getCharges,
    setToPaid,
    getChargeById,
    deleteCharge,
    emitInvoice,
    getInvoices,
    sendInvoiceEmail,
    cancelInvoice,
    getChargeData,
    getChargeInvoicesData,
    handleChangeToPaid,
    handleChargeBackCharge,
    invoices,
    charge,
    getChargeSubsellers,
    getStatmentChargeById,
    sendCancelMail
  };
}
