import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Divider,
  Flex,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon,
  Link as ChakraLink,
  Button as ChakraButton,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { Alert, BackButton } from "@/components";
import { WithAuth } from "@/hoc";
import {
  getInitials,
  getColorBadgeStatus,
  getStatus,
  showToast,
  phone_format,
  formatDate,
} from "@/utils";
import { useAlert, useCheckout, useCharge } from "@/hooks";
import { FaWhatsapp, FaRegEnvelope } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InvoiceTable } from "@/layouts/ChargeHistory/InvoiceTable";
import { ExcludeCharge } from "@/layouts/ExcludeCharge";
import { Chargeback, ChangeToPaid } from "@/layouts";
import { CustomModal } from "@/components/CustomModal";
import { InfoIconNotFilled } from "@/styles/icons/InfoIconNotFilled";
import { Container } from "./styles";
import { format } from "date-fns";
import { ChargeTriesProps, ErrorModal } from "@/layouts/ErrorModal";
import ChargeInstallments from "@/components/FeatureToggleComponents/charges/ChargeInstallments";
import { SplitTable } from "@/layouts/ChargeHistory/SplitTable";
import { ReceivableTable } from "@/layouts/ChargeHistory/receivablesTables";

declare global {
  interface Window {
    cancelMailUrl?: string;
  }
}

function CobrancaDetalhes() {
  const [fraudCheckboxState, setFraudCheckboxState] = useState<boolean>(false);
  const [chargebackModalStateInfo, setChargebackModalStateInfo] = useState<boolean>(false);
  const [splitData, setSplitData] = useState([]);
  const [subDomain, setSubDomain] = useState("");
  const [chargebackModalState, setChargebackModalState] = useState(false);
  const [deleteChargeModalState, setDeleteChargeModalState] = useState(false);
  const [errorModalState, setErrorModalState] = useState(false);
  const [errorModal, setErrorModal] = useState<ChargeTriesProps>(null);
  const user = JSON.parse(localStorage.getItem("barte-user-info"));
  const { id } = useParams();
  const navigate = useNavigate();
  const { onClose, showAlert, cancelRef, setShowAlert } = useAlert();
  const viewSplit = localStorage.getItem("barte-features-list-user").includes("view_split_subseller_payment_link");
  const {
    deleteCharge,
    getChargeData,
    handleChangeToPaid,
    handleChargeBackCharge,
    getStatmentChargeById,
    charge,
    getChargeSubsellers,
    sendCancelMail
  } = useCharge();
  const { getCheckoutDefault } = useCheckout();
  const [statementCharge, setStatementCharge] = useState({} as any);
  const [isLoadingCancelMail, setIsLoadingCancelMail] = useState(false);
  async function getSplitData() {
    try {
      const response = await getChargeSubsellers(id);
      setSplitData(response?.data);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }

  }



  async function getStatementCharge(id: string) {
    try {
      const receivables = await getStatmentChargeById(id)
      setStatementCharge(receivables)
    } catch (error) {
      showToast({
        type: "error",
        message: "Falha ao carregar as informações dos recebiveis.",
      });
    }
  }

  useEffect(() => {
    if (!id) return;
    getChargeData(String(id));
    if(user?.roles?.includes("SELLER_FINANCES")) {
      getStatementCharge(id);
    } 
    if (viewSplit) {
      getSplitData();
    }
  }, [id]);

  async function getSubdomainData() {
    try {
      const checkoutResponse = await getCheckoutDefault();
      setSubDomain(checkoutResponse.data.subDomain);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }
  const [isActiveCharge, setIsActiveCharge] = useState(false);
  const initials = getInitials(charge?.customerName ?? "-");
  const featureTogglesUser = localStorage?.getItem("barte-features-list-user");
  const paymentMethod = charge.paymentMethod === "Boleto" ? "boleto" : "pix";
  const paymentMethodMessage =
    charge.paymentMethod === "Boleto" ? "Ver Boleto" : "Ver código PIX";

  function handleToggleDeleteChargeModal() {
    setDeleteChargeModalState(!deleteChargeModalState);
  }

  async function handleDeleteCharge() {
    try {
      await deleteCharge(id);

      showToast({
        type: "success",
        message: "Sucesso na exclusão da cobrança.",
      });

      setDeleteChargeModalState(!deleteChargeModalState);

      navigate(-1);
    } catch (error) {
      error?.response?.data?.errors.map((error) => {
        showToast({
          type: "error",
          message: `${error?.code} - ${error?.description}`,
        });
      });
    }
  }

  const chargebackModalContent = (props?: any) => (
    <Container>
      <section className="list-data">
        <p>
          Você recebeu uma contestação de cobrança feito pelo seguinte cliente:
        </p>
        <ul>
          <li>Nome do cliente: {charge?.customerName}</li>
          <li>Documento do cliente: {props?.customerDocument}</li>
          <li>Email do cliente: {props?.customerEmail}</li>
          <li>Telefone do cliente: {props?.customerPhone}</li>
        </ul>
      </section>
      <section className="list-data">
        <p>
          A contestação é relativa à venda:
        </p>
        <ul>
          <li>Título da cobrança: {props?.title}</li>
          <li>ID da cobrança: {props?.uuid}</li>
          <li>Valor: {props?.formattedValue}</li>
          <li>Data de pagamento: {props?.formattedPaidDate}</li>
        </ul>
      </section>
      <Divider />
      <section className="chargeback-documents">
        <p>
          Para contestar uma solicitação de estorno, deve ser enviado um email para <b>antifraude@barte.com</b>{" "}
          em até 5 dias corridos após a solicitação, dia {props?.chargebackDeadline ? format(new Date(props?.chargebackDeadline), "dd/MM/yyyy") : ""}, contendo os seguintes documentos:
        </p>
        <ol>
          <li>Relato do ocorrido, informando os documentos que serão apresentados na defesa da contestação;</li>
          <li>Cadastro do Portador (Nome do Titular do Cartão, Nome cadastrado no site, CPF, E mail, Endereço, telefone fixo, celular);</li>
          <li>Nota fiscal;</li>
          <li>Pedido da compra;</li>
          <li>Cópia da tela com os dados do meio de pagamento;</li>
          <li>Comprovação da prestação do serviço e/ou protocolo assinado pelo portador;</li>
          <li>Evidência de grau de parentesco entre o portador e o comprador;</li>
          <li>Carta do verdadeiro portador reconhecendo a despesa.</li>
        </ol>
        <p className="italic-text">
          *Não é obrigatório o envio de TODAS as documentações solicitadas, porém quanto mais documentações apresentadas, melhor será a defesa.
        </p>
        <Divider />
        <p className="final-content">
          Também enviamos essas informações para o seu email cadastrado na plataforma.
        </p>
        <p>
          Caso você não tenha recebido, entre em contato com o nosso atendimento.
        </p>
      </section>
    </Container>
  );

  async function handleSendCancelMail() {
    let url: string;
    try {
      setIsLoadingCancelMail(true);
      const response = await sendCancelMail(id);
      url = response.data.url;
      window.cancelMailUrl = url;
      setTimeout(() => {
        if (window.cancelMailUrl) {
          window.open(window.cancelMailUrl, "_blank", "noopener,noreferrer");
          window.cancelMailUrl = undefined;
        }
      }, 100);
      
    } catch (error) {
      showToast({
        type: "error",
        message: "Erro ao gerar a carta de cancelamento."
      });
    } finally {
      setIsLoadingCancelMail(false);
    }
  }

  const elem = chargebackModalContent(charge);

  useEffect(() => {
    setIsActiveCharge(charge.formattedStatus === "Ativo" ? true : false);
  }, [charge]);

  function handleOpenErrorModal(uuid: string) {
    setErrorModalState(!errorModalState);
    setErrorModal(charge.chargesTries.filter(item => item.uuid === uuid).at(0));
  }

  return (
    <>
      {
        charge &&
        <>

          <ErrorModal
            confirmButtonText="Concluir"
            status="Erro"
            clientName={charge?.customerName}
            modalState={errorModalState}
            setModalState={setErrorModalState}
            error={errorModal}
          />
          <Alert
            closeButtonText="Não"
            confirmButtonText="Sim"
            isConfirmAlert={false}
            isOpen={deleteChargeModalState}
            onConfirm={handleDeleteCharge}
            onClose={handleToggleDeleteChargeModal}
            title="Tem certeza que seja deletar essa cobrança?"
            messageBody="Essa ação não poderá ser desfeita"
            leastDestructiveRef={cancelRef}
          />
          <CustomModal
            isOpen={chargebackModalStateInfo}
            headerIcon={<InfoIconNotFilled color="#FFC53D" size={19} />}
            headerText="Dados da Contestação"
            confirmButtonText="Ok"
            modalBody={elem}
            onClose={() => setChargebackModalStateInfo(false)}
            onConfirm={() => setChargebackModalStateInfo(false)}
          />

          <Box position={`${chargebackModalStateInfo ? "fixed" : "relative"}`}>
            <Box my={4}>
              <BackButton />
            </Box>

            <Divider />

            <Flex align="center" justify="space-between" my="8" mr="4rem">
              <Flex align="center" gridGap="4">
                {initials && (
                  <Badge
                    fontSize="1rem"
                    minWidth="35px"
                    minHeight="35px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    colorScheme="yellow"
                    color="gray.800"
                  >
                    {initials}
                  </Badge>
                )}
                {charge?.customerId ? (
                  <Tooltip
                    label="Clique para ver detalhes do cliente"
                  >
                    <Link
                      to={`/clientes/${charge?.customerId}`}
                      color="gray.800"
                      style={{
                        fontWeight: 600,
                        fontSize: "25px"
                      }}
                      target="_blank"
                    >
                      {charge.customerName}
                    </Link>
                  </Tooltip>
                ) : (
                  <Text
                    fontWeight={600}
                    fontSize="25px"
                    color="gray.800"
                  >
                    {charge.customerName}
                  </Text>
                )}

              </Flex>

              <Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="right"
                  color="gray.800"
                >
                  <Flex fontSize="1.2rem" alignItems="center">
                    {charge.customerPhone ? (
                      <>
                        <Icon as={FaWhatsapp} boxSize="1.4rem" mr="6px" />
                        <ChakraLink
                          id="clique_em_whatsapp"
                          href={`https://Api.whatsapp.com/send?phone=${charge?.countryCode?.split("+")[1] ?? "55"}${charge?.customerPhone}`}
                          target="_blank"
                        >
                          Contatar via whatsapp
                        </ChakraLink>
                      </>
                    ) : (
                      <>
                        <Icon
                          id="clique_em_whatsapp"
                          as={FaRegEnvelope}
                          boxSize="1.4rem"
                          mr="6px"
                          color="gray.400"
                        />
                        <Text id="clique_em_email" color="gray.400">
                          Contatar via whatsapp
                        </Text>
                      </>
                    )}
                  </Flex>
                  <Flex ml="1.6rem">
                    {charge.customerPhone
                      ? phone_format(charge.customerPhone)
                      : "-"}
                  </Flex>
                </Flex>

                <Flex
                  ml="3rem"
                  flexDirection="column"
                  justifyContent="right"
                  color="gray.800"
                >
                  <Flex fontSize="1.2rem" alignItems="center">
                    {charge.customerEmail ? (
                      <>
                        <Icon as={FaRegEnvelope} boxSize="1.4rem" mr="6px" />
                        <ChakraLink
                          id="clique_em_email"
                          href={`mailto:${charge.customerEmail}`}
                          target="_blank"
                        >
                          Contatar via email
                        </ChakraLink>
                      </>
                    ) : (
                      <>
                        <Icon
                          id="clique_em_email"
                          as={FaRegEnvelope}
                          boxSize="1.4rem"
                          mr="6px"
                          color="gray.400"
                        />
                        <Text id="clique_em_email" color="gray.400">
                          Contatar via email
                        </Text>
                      </>
                    )}
                  </Flex>
                  <Flex ml="1.6rem">
                    {charge.customerEmail ? charge.customerEmail : "-"}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Divider />

            <SimpleGrid columns={4} minChildWidth={100} my="8">
              <Text as="h2" fontSize="20" fontWeight="600">
                Informações gerais
              </Text>

              <Text></Text>

              {(charge.status === "SCHEDULED" || charge.status === "LATE" || charge.status === "FAILED") && (
                <>
                  {(charge.paymentMethod === "Boleto" ||
                    charge.paymentMethod === "Pix") && (
                      <ChakraLink
                        href={subDomain !== "" ? `${import.meta.env.VITE_CHECKOUT_URL}${subDomain}/${paymentMethod}/${id}` : `${import.meta.env.VITE_CHECKOUT_URL}${paymentMethod}/${id}`}
                        target="_blank"
                        color="black"
                        border="1px solid"
                        borderColor="gray.200"
                        h="24px"
                        borderRadius="3"
                        variant="outline"
                        maxW="145px"
                        textAlign="center"
                        transition="background-color .2s"
                        _hover={{
                          transition: "background-color .2s",
                          bgColor: "gray.100",
                          textDecoration: "none",
                        }}
                      >
                        {paymentMethodMessage}
                      </ChakraLink>
                    )}

                  {charge.paymentMethod === "Cartão de Crédito" && <Text></Text>}
                  <ExcludeCharge
                    handleToggleDeleteChargeModal={handleToggleDeleteChargeModal}
                  />
                </>
              )}

              {charge.status === "PAID" &&
                charge.paymentMethod === "Cartão de Crédito" && !featureTogglesUser?.includes("view_disable_refund") && (
                  <>
                    <Text></Text>
                    <Chargeback
                      handleToggleChargebackModal={() =>
                        setChargebackModalState(true)
                      }
                    />
                  </>
              )}
            </SimpleGrid>
            <SimpleGrid columns={4} my="8" rowGap={8} columnGap={0}>
              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Produto/Serviço
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {charge.title}
                </Text>
              </Flex>
              <Flex direction="column" align="flex-start">
                <Text color="gray.300" fontSize="14px">
                  Status
                </Text>
                <Flex gap=".3rem">
                  <Badge variant={getColorBadgeStatus(charge.status)} fontSize="14px">
                    {getStatus(charge.status)}
                  </Badge>
                  {charge.status === "DISPUTE" && (
                    <ChakraButton
                      type="button"
                      color="blue.200"
                      fontWeight="400"
                      variant="ghost"
                      fontSize="14px"
                      h="20px"
                      p="0"
                      m="0"
                      transition="all .2s"
                      _hover={{
                        transition: "all .2s",
                        filter: "brightness(.85)",
                        textDecor: "underline"
                      }}
                      onClick={() => setChargebackModalStateInfo(true)}
                    >
                      Saiba mais
                    </ChakraButton>
                  )}
                  
                </Flex>
              </Flex>

              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Método de pagamento
                </Text>

                <Flex align="center" gap="2px">
                  <Text color="gray.800" fontSize="14px"> {charge.paymentMethod === "CREDIT_CARD_EARLY_MIXED" ? "Cartão de Crédito" : charge.paymentMethod} </Text>
                  {(charge?.tradeAgreement?.type === "DEFAULT" || charge?.tradeAgreement === null ) && charge.paymentMethod !== "CREDIT_CARD_EARLY_MIXED"  && 
                    <Text>
                      <ChargeInstallments installments={charge?.installments} />
                    </Text>
                  }

                </Flex>
              </Flex>

              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Código da cobrança
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {charge.uuid}
                </Text>
              </Flex>

              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Vencimento
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {formatDate(charge.expirationDate)}
                </Text>
              </Flex>

              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Data do pagamento
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {charge?.formattedPaidDate ? (
                    <>{charge?.formattedPaidDate}</>
                  ) : (
                    <>-</>
                  )}
                </Text>
              </Flex>

              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Valor da Cobrança
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {charge.formattedValue}
                </Text>
              </Flex>

              {charge.paymentMethod === "Boleto" && (
                <Flex direction="column">
                  <Text color="gray.300" fontSize="14px">
                    Juros e Multa
                  </Text>

                  <Text color="gray.800" fontSize="14px">
                    {charge?.interestAmount ? (
                      <>{charge?.formattedInterestAmount}</>
                    ) : (
                      <>-</>
                    )}
                  </Text>
                </Flex>
              )}

              <ChangeToPaid status={charge?.status} setShowAlert={setShowAlert} />
              {charge.status === "REFUND" && (
                <Flex 
                  direction="column" 
                  minW="300px"
                >
                  <Text color="gray.300" fontSize="14px">
                    Carta de cancelamento
                  </Text>
                  <Text>
                    <ChakraButton
                      id="carta_cancelamento"
                      h="20px"
                      p="0"
                      m="0"
                      color="newGray.500"
                      textAlign="left"
                      fontWeight="400"
                      variant="ghost"
                      transition="all .2s"
                      textDecoration="underline"
                      _hover={{
                        transition: "all .2s",
                        filter: "brightness(.8)",
                      }}
                      onClick={handleSendCancelMail}
                      isLoading={isLoadingCancelMail}
                      isDisabled={isLoadingCancelMail}
                    >
                      Abrir carta de cancelamento
                    </ChakraButton>
                  </Text>
                </Flex>
              )}
            </SimpleGrid>

            <Divider />

            <Text as="h2" fontSize="20" fontWeight="600" my="8">
              Informações de pagamento
            </Text>

            <SimpleGrid columns={4} my="8" rowGap={8} columnGap={0}>


              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Cartão de crédito
                </Text>

                <Text color="gray.800" fontSize="14px">
                  {charge?.cardResponse?.last4digits}
                </Text>
              </Flex>


              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Bandeira
                </Text>

                <Text color="gray.800" fontSize="14px">
                {charge?.cardResponse?.brand}

                </Text>
              </Flex>


              <Flex direction="column">
                <Text color="gray.300" fontSize="14px">
                  Títular do cartão
                </Text>

                <Text color="gray.800" fontSize="14px">
                {charge?.cardResponse?.cardHolderName}

                </Text>
              </Flex>

            </SimpleGrid>

            <Divider />
            {(user?.roles?.includes("SELLER_CONFIG_NF") ||
              user?.roles?.includes("SELLER_MASTER") ||
              user?.roles?.includes("SELLER_ADMIN") ||
              user?.roles?.includes("SELLER_BACKOFFICE")) && <InvoiceTable />}

            <Divider />
            {charge?.paymentReceiptType && user?.roles?.includes("SELLER_FINANCES") && <ReceivableTable statementCharge={statementCharge?.data} />}

             {viewSplit && (
              <SplitTable
                splitData={splitData}
              />
            )}

            {(charge?.chargesTries && charge?.paymentMethod === "Cartão de Crédito") && (
              <>
                <Text as="h2" fontSize="20" fontWeight="600" mt="8">
                  Histórico de tentativas
                </Text>
                <Table>
                  <Thead>
                    <Tr>
                      <Th
                        my="4"
                        color="gray.300"
                        fontSize="14px"
                        fontWeight="normal"
                      >
                        Tentativa
                      </Th>
                      <Th
                        my="4"
                        color="gray.300"
                        fontSize="14px"
                        fontWeight="normal"
                      >
                        Valor
                      </Th>
                      <Th
                        my="4"
                        color="gray.300"
                        fontSize="14px"
                        fontWeight="normal"
                      >
                        Status
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {charge?.chargesTries?.map(
                      ({ expirationDate, status, uuid, formattedValue, createdAt }, index) => (
                        <Tr key={uuid}>
                          <Td>{formatDate(createdAt ? createdAt : expirationDate)}</Td>
                          <Td>{formattedValue}</Td>
                          <Td>
                            {status && status !== "FAILED" ? (
                              <Badge
                                fontSize="1rem"
                                variant={getColorBadgeStatus(status)}
                              >
                                {getStatus(status)}
                              </Badge>
                            ) : (
                              <>
                                {status !== "FAILED" && (
                                  "-"
                                )}
                              </>
                            )}
                            {status === "FAILED" && (
                              <Button
                                variant="ghost"
                                p="0"
                                m="0"
                                _hover={{
                                  p: "0",
                                  m: "0",
                                  transition: "all .2s",
                                  filter: "brightness(.8)"
                                }}
                                onClick={() => handleOpenErrorModal(uuid)}
                              >
                                <Badge
                                  fontSize="1rem"
                                  variant={getColorBadgeStatus(status)}
                                >
                                  <Flex align="center" gap="7px">
                                    Pagamento falhou
                                    <InfoIconNotFilled color="#8E1E1E" size={14} />
                                  </Flex>
                                </Badge>
                              </Button>
                            )}
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </>
            )}

            <Alert
              leastDestructiveRef={cancelRef}
              onClose={onClose}
              onConfirm={() => handleChangeToPaid(id, onClose)}
              isOpen={showAlert}
              title="Essa cobrança já foi paga?"
              messageBody={
                <p>
                  Confirme para alterar o status dessa cobrança para <b>Paga Manualmente</b>. Essa alteração não poderá ser desfeita.
                </p>
              }
              closeButtonText="Cancelar"
              confirmButtonText="Confirmar"
            />

            <Alert
              leastDestructiveRef={cancelRef}
              onClose={() => setChargebackModalState(false)}
              onConfirm={() => handleChargeBackCharge(id, setChargebackModalState, fraudCheckboxState)}
              isOpen={chargebackModalState}
              checkBox
              checkBoxValue={fraudCheckboxState}
              checkBoxFunction={setFraudCheckboxState}
              checkBoxText="Possível fraude"
              closeButtonText="Cancelar"
              title="Deseja estornar essa cobrança?"
              messageBody="Essa ação não poderá ser desfeita."
            />
          </Box>
        </>

      }
    </>
  );
}

export default WithAuth(CobrancaDetalhes);
